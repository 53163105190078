import styled from 'styled-components';

interface SoarLogoProps {
    width?: string;
    height?: string;
    className?: string;
}

const SoarLogo = (props: SoarLogoProps) => {
    return (
        <SoarLogoContainer className={props.className}>
            <SoarLogoImage src={'assets/logos/soar_logo_large.png'} alt="Soar.Earth Logo" />
        </SoarLogoContainer>
    );
};

export default SoarLogo;

const SoarLogoContainer = styled.div<SoarLogoProps>`
    position: relative;
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height};
`;
const SoarLogoImage = styled.img`
    width: 100%;
    height: auto;
`;
