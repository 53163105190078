import styled from 'styled-components';
import SoarHelper from '../../../lib/soar-helper';
import Analytics from '../../../lib/user-analytics';
import { useState, useEffect } from 'react';
import ApiListings from '../../../api/api-listings';

const DEBOUNCE_TIME = 300; //ms
const MINIMUM_SEARCH_CHARACTERS = 2;

interface DiscoverNavBarProps {
    selectedSearchTerm: string;
    setSelectedSearchTerm: (searchTerm: string | undefined) => void;
    searchKeyword: string;
    setSearchKeyword: (searchKeyword: string) => void;
}

const DiscoverNavBar = (props: DiscoverNavBarProps) => {
    const { searchKeyword, setSearchKeyword } = props;
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');

    useEffect(() => {
        if (!searchKeyword) {
            setDebouncedSearchTerm('');
        }
    }, [searchKeyword]);

    // Debounce the input using useEffect
    useEffect(() => {
        if (searchKeyword.length === 0) {
            setDebouncedSearchTerm('');
            props.setSelectedSearchTerm('');
        }

        if (searchKeyword.length < MINIMUM_SEARCH_CHARACTERS) {
            return;
        }

        const debounceTimeout = setTimeout(() => {
            setDebouncedSearchTerm(searchKeyword);
        }, DEBOUNCE_TIME);

        return () => {
            clearTimeout(debounceTimeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyword]);

    // Fetch data when debouncedSearchTerm changes
    useEffect(() => {
        if (debouncedSearchTerm && searchKeyword) {
            Analytics.Event('Navbar', 'Mobile Navbar - Search', debouncedSearchTerm);
            ApiListings.cancelGetListings('Cancel get listings');
            props.setSelectedSearchTerm(debouncedSearchTerm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    const handleClickedLogo = () => {
        Analytics.Event('Navbar', 'Mobile Navbar - Clicked Logo');
        window.location.reload();
    };

    return (
        <Navbar>
            {SoarHelper.isSoar() ? (
                <NavImage
                    onClick={handleClickedLogo}
                    src={'/assets/logos/soar-icon-search.png'}
                    data-testid="mobile-soar-logo"
                />
            ) : (
                <SubdomainNavImage
                    onClick={handleClickedLogo}
                    src={'/assets/subdomain/subdomain-soar-icon-search.png'}
                    data-testid="mobile-soar-plus-logo"
                />
            )}
            <NavInput
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                type="text"
                placeholder={'Search for Maps!'}
            />
            {searchKeyword.length > 0 ? (
                <CloseIcon onClick={() => setSearchKeyword('')} aria-label="Clear input" title="Clear input" />
            ) : null}
        </Navbar>
    );
};

export default DiscoverNavBar;

export const Navbar = styled.div`
    background-color: transparent;
    height: 55px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
`;

const NavImage = styled.img`
    cursor: pointer;
    margin: 10px 5px;
    height: 34px;
`;

const SubdomainNavImage = styled.img`
    cursor: pointer;
    margin: 12px 5px;
    height: 29px;
`;

const NavInput = styled.input`
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    color: white;
    outline: none;
    padding: 0 5px;
    font-size: 14px;
    margin: 8px 8px 8px 0px;
    border-radius: 6px;
`;

const CloseIcon = styled.button`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 15px;
    width: 15px;
`;
