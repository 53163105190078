import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { ListingDTO } from '../../../api/model';

import 'react-tabs/style/react-tabs.css';

import { selectBookmarkedMaps, selectBookmarkedMapsIsLoading } from '../../../store/Map/BookMarks/selectors';
import { actionFetchBookmarkedMaps } from '../../../store/Map/BookMarks/actions';
import { selectSatelliteOrders } from '../../../store/Map/Tasks/selectors';
import { actionFetchSatelliteOrders } from '../../../store/Map/Tasks/actions';
import {
    selectDrawProjects,
    selectDrawProjectsIsFetched,
    selectSharedDrawProjects,
    selectSharedDrawProjectsIsFetched,
} from '../../../store/Map/DrawProject/selectors';
import { actionFetchSharedDrawProjects, actionFetchUserDrawProjects } from '../../../store/Map/DrawProject/actions';
import { StoaryResponse } from '../../../api/stoaryModel';
import MobileProfileSatelliteOrders from './Listings/satellite-orders';
import MobileProfileStoaries from './Listings/stoaries';
import MobileProfileBookmarks from './Listings/bookmarks';
import MobileProfileMyListings from './Listings/listings';

interface MobileProfileListingsProps {
    listings: ListingDTO[] | undefined;
    listingLoading: boolean;
}

const MobileProfileListings = (props: MobileProfileListingsProps) => {
    const dispatch = useDispatch();
    const satelliteOrders = useSelector(selectSatelliteOrders);
    const bookmarkedMaps = useSelector(selectBookmarkedMaps);
    const bookmarkedMapsLoading = useSelector(selectBookmarkedMapsIsLoading);

    const drawProjects = useSelector(selectDrawProjects);
    const isFetchedDrawProjects = useSelector(selectDrawProjectsIsFetched);
    const sharedDrawProjects = useSelector(selectSharedDrawProjects);
    const isFetchedSharedDrawProjects = useSelector(selectSharedDrawProjectsIsFetched);

    const [satelliteOrdersLoading, setSatelliteOrdersLoading] = useState<boolean>(true);
    const [drawProjectsLoading, setDrawProjectsLoading] = useState<boolean>(true);
    const [sharedDrawProjectsLoading, setSharedDrawProjectsLoading] = useState<boolean>(true);
    const [filteredStoaries, setFilteredStoaries] = useState<StoaryResponse[]>([]);
    const [stoaries, setStoaries] = useState<StoaryResponse[]>([]);

    const handleTabSelect = (tabIndex: number) => {
        switch (tabIndex) {
            case 3: {
                if (!bookmarkedMaps) {
                    dispatch(actionFetchBookmarkedMaps());
                }
                break;
            }
            case 2: {
                if (!satelliteOrders) {
                    dispatch(actionFetchSatelliteOrders());
                }
                break;
            }
            case 1: {
                if (!isFetchedDrawProjects) {
                    dispatch(actionFetchUserDrawProjects());

                    if (!isFetchedSharedDrawProjects) {
                        dispatch(actionFetchSharedDrawProjects());
                    }
                }
                break;
            }
        }
    };

    const reloadStoaries = async () => {
        setStoaries([] as StoaryResponse[]);

        dispatch(actionFetchUserDrawProjects());
        dispatch(actionFetchSharedDrawProjects());
    };

    useEffect(() => {
        if (satelliteOrders) {
            setSatelliteOrdersLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [satelliteOrders]);

    useEffect(() => {
        if (isFetchedDrawProjects) {
            setDrawProjectsLoading(false);
            if (isFetchedSharedDrawProjects) {
                setSharedDrawProjectsLoading(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchedDrawProjects, isFetchedSharedDrawProjects]);

    useEffect(() => {
        setStoaries([...drawProjects, ...sharedDrawProjects]);
    }, [drawProjects, sharedDrawProjects]);

    useEffect(() => {
        setFilteredStoaries(
            stoaries.sort((a: StoaryResponse, b: StoaryResponse) =>
                (a.updatedAt ?? a.createdAt) > (b.updatedAt ?? b.createdAt) ? -1 : 1
            )
        );
    }, [stoaries]);

    return (
        <ProfileContent>
            <ListingTabs onSelect={handleTabSelect}>
                <ListingTabsList>
                    <ListingTab>
                        <MyMapsIcon />
                        <ListingTabTitle>My Maps</ListingTabTitle>
                    </ListingTab>
                    <ListingTab>
                        <MyStoariesIcon />
                        <ListingTabTitle>My Stoaries</ListingTabTitle>
                    </ListingTab>
                    <ListingTab>
                        <SatelliteOrdersIcon />
                        <ListingTabTitle>Satellite Orders</ListingTabTitle>
                    </ListingTab>
                    <ListingTab>
                        <BookmarksIcon />
                        <ListingTabTitle>My Bookmarks</ListingTabTitle>
                    </ListingTab>
                </ListingTabsList>
                <ListingTabPanel>
                    <MobileProfileMyListings listings={props.listings} listingsLoading={props.listingLoading} />
                </ListingTabPanel>
                <ListingTabPanel>
                    <MobileProfileStoaries
                        stoaries={filteredStoaries}
                        stoariesLoading={drawProjectsLoading || sharedDrawProjectsLoading}
                        updateStoaries={() => reloadStoaries()}
                    />
                </ListingTabPanel>
                <ListingTabPanel>
                    <MobileProfileSatelliteOrders
                        satelliteOrders={satelliteOrders}
                        satelliteOrdersLoading={satelliteOrdersLoading}
                    />
                </ListingTabPanel>
                <ListingTabPanel>
                    <MobileProfileBookmarks bookmarks={bookmarkedMaps} bookmarksLoading={bookmarkedMapsLoading} />
                </ListingTabPanel>
            </ListingTabs>
        </ProfileContent>
    );
};

export default MobileProfileListings;

const ProfileContent = styled.div`
    flex: 0 1 auto;
    position: relative;
`;

const ListingTabs = styled(Tabs)`
    height: 100%;
    position: relative;
`;

const ListingTabsList = styled(TabList)`
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    width: 100%;
    padding: 0;
    margin-bottom: 2px;
`;

const Icon = styled.span`
    display: inline-block;
    width: 50px;
    height: 50px;

    background-position: center;
    background-repeat: no-repeat;
`;

const GridIcon = styled(Icon)`
    background-image: url('/assets/mobile/grid-view-white.svg');
`;

const ListIcon = styled(Icon)`
    background-image: url('/assets/mobile/list-view-white.svg');
`;

const SatelliteIcon = styled(Icon)`
    background-image: url('/assets/mobile/satellite-view-white.svg');
`;

const MyMapsIcon = styled(Icon)`
    background-image: url('/assets/dashboard/my-maps_white.svg');
    background-size: 30px 30px;
`;

const MyStoariesIcon = styled(Icon)`
    background-image: url('/assets/stoaries-icons/draw-project-white-cropped.svg');
    background-size: 30px 30px;
`;

const SatelliteOrdersIcon = styled(Icon)`
    background-image: url('/assets/dashboard/satellite_white.svg');
    background-size: 30px 30px;
`;

const BookmarksIcon = styled(Icon)`
    background-image: url('/assets/dashboard/bookmark_white.svg');
    background-size: 30px 30px;
`;

const ListingTabTitle = styled.span`
    color: ${(props) => props.theme.color.yellow};

    white-space: nowrap;
    transition: all 0.3s ease;
`;

const ListingTab = styled(Tab)`
    list-style: none;

    flex: 1;
    color: ${(props) => props.theme.color.white};

    height: 50px;
    text-align: center;
    align-content: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    transition: all 0.5s ease-in-out;

    ${ListingTabTitle} {
        display: none;
        font-size: 0;
    }

    &.react-tabs__tab--selected {
        width: 40%;
        background: transparent;
        border: none;
        outline: none;

        border-bottom: 3px solid ${(props) => props.theme.color.yellow};
        color: ${(props) => props.theme.color.yellow};
        font-weight: 700;

        ${ListingTabTitle} {
            font-size: ${(props) => props.theme.fontSize.normal} !important;
            display: inline-block;
            padding-right: 10px;
        }

        ${GridIcon} {
            background-image: url('/assets/mobile/grid-view-yellow.svg');
        }

        ${ListIcon} {
            background-image: url('/assets/mobile/list-view-yellow.svg');
        }

        ${SatelliteIcon} {
            background-image: url('/assets/mobile/satellite-view-yellow.svg');
        }

        ${MyMapsIcon} {
            background-image: url('/assets/dashboard/my-maps_yellow.svg');
        }

        ${MyStoariesIcon} {
            background-image: url('/assets/stoaries-icons/draw-project-yellow-cropped.svg');
        }

        ${SatelliteOrdersIcon} {
            background-image: url('/assets/dashboard/satellite_yellow.svg');
        }

        ${BookmarksIcon} {
            background-image: url('/assets/dashboard/bookmark_yellow.svg');
        }
    }
`;

const ListingTabPanel = styled(TabPanel)`
    color: ${(props) => props.theme.color.white};

    max-height: calc(100% - 50px);
    overflow-y: auto;

    position: relative;
`;
