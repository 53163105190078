import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ListingType } from '../../../../../api/model';
import ListingHelper from '../../../../../lib/listing-helper';
import { selectActiveAllMap } from '../../../../../store/Map/SuperMap/selectors';
import ImagePreview from '../../../../Shared/image-preview';
import ReportMap from '../../../../Shared/ReportMap/report-map';
import EmbeddedMapDialog from '../../EmbedMap/embedded-map-dialog';
import SideDrawerSocial, { StyledSocialButton } from './side-drawer-social';
import SoarHelper from '../../../../../lib/soar-helper';
import ShareService from '../../../../../lib/share-service';
import Analytics from '../../../../../lib/user-analytics';

interface ShareComponentProps {
    listingPage?: boolean;
}

const MapOptions = (props: ShareComponentProps) => {
    const selectedMap = useSelector(selectActiveAllMap);

    if (!selectedMap) {
        return <React.Fragment />;
    }

    // Do not show share links for satellite orders, as they are private
    if (selectedMap.listingType === ListingType.ORDER) {
        return <React.Fragment />;
    }

    return (
        <DrawShareContainer>
            <DrawShareImage
                src={ListingHelper.getPreviewUrlForListing(selectedMap.id)}
                width={378}
                height={70}
                alt={selectedMap.title || 'Share Image Preview'}
            />
            <DrawShareOptions>
                {SoarHelper.isMobile() ? (
                    <DrawShareIcons>
                        <StyledSocialButton
                            iconUrl={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='white'%3E%3Cpath d='M307 34.8c-11.5 5.1-19 16.6-19 29.2l0 64-112 0C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96l96 0 0 64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z'/%3E%3C/svg%3E`}
                            text={'Share by Link'}
                            onClick={() => {
                                const shortUrl = ShareService.shareMapUrl(selectedMap.seoTitle || selectedMap.id);
                                const currentDomain =
                                    SoarHelper.isSoar() && shortUrl ? shortUrl : `${window.location.href}`;

                                const copyToClipboard = (shortUrl: string) => {
                                    if (navigator.clipboard) {
                                        navigator.clipboard.writeText(shortUrl);
                                        alert('Share link copied to clipboard');
                                        Analytics.Event(
                                            `Social Media - Copied To Share - ${currentDomain}`,
                                            'Link',
                                            selectedMap.id
                                        );
                                    } else {
                                        alert('No clipboard available. Please copy the link manually');
                                    }
                                };

                                if (navigator.share) {
                                    navigator
                                        .share({
                                            title: selectedMap.title,
                                            text: selectedMap.description,
                                            url: shortUrl,
                                        })
                                        .then(() => {
                                            console.log('Successfully shared');
                                            Analytics.Event(
                                                `Social Media - Shared with navigator - ${currentDomain}`,
                                                'Link',
                                                selectedMap.id
                                            );
                                        })
                                        .catch(() => {
                                            copyToClipboard(shortUrl);
                                        });
                                } else {
                                    copyToClipboard(shortUrl);
                                }
                            }}
                        />
                    </DrawShareIcons>
                ) : (
                    <React.Fragment>
                        <DrawShareIcons>
                            <SideDrawerSocial />
                        </DrawShareIcons>
                        <DrawShareIcons>
                            {!props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}
                            <ReportMap />
                        </DrawShareIcons>
                    </React.Fragment>
                )}
            </DrawShareOptions>
        </DrawShareContainer>
    );
};

export default MapOptions;

const DrawShareContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const DrawShareImage = styled(ImagePreview)`
    width: 378px;
    height: 70px;
    object-fit: cover;
    margin: 15px 5px 0px !important;
    border-radius: 6px;
    max-width: 97%;
`;

const DrawShareOptions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const DrawShareIcons = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    user-select: none;

    .embed-map-icon {
        height: 20px;
        margin: 0px;
        margin-top: 3px;
        font-size: 16px;
    }

    .report-map-icon {
        height: 20px;
        margin: -12px 0 0;
        font-size: 16px;
    }
`;
