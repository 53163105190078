import { actionTypes as at } from './constants';
import { AppState } from '../../root-reducer';
import ApiListings from '../../../api/api-listings';
import { actionFlyTo } from '../../App/actions';
import { toast } from 'react-toastify';
import GeoUtil from '../../../lib/geo-util';
import UriHelper from '../../../lib/uri-helper';
import { ListingType, ListingDTO } from '../../../api/model';
import { LatLng } from 'leaflet';

function sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const actionSetActiveMapById = (tileLayerId: number) => {
    return async (dispatch, getState) => {
        const appState: AppState = getState();
        const currentActiveMap = appState.superMapDomain.activeTileLayer;

        if (!currentActiveMap || currentActiveMap.id !== tileLayerId) {
            const existingTileLayers = appState.superMapDomain.tileLayers;
            if (existingTileLayers[tileLayerId]) {
                return dispatch({
                    type: at.MAP_ACTIVE_TILE_LAYER_CHANGED,
                    payload: existingTileLayers[tileLayerId],
                });
            }
            try {
                const tileLayer = await ApiListings.getListing(tileLayerId);
                dispatch({
                    type: at.MAP_ACTIVE_TILE_LAYER_CHANGED,
                    payload: tileLayer,
                });
                dispatch(actionAppendMap(tileLayer));
            } catch (err) {
                // Generic reply since the map could have been deleted or private and the user does not need to know details
                toast.error('The map you are looking for can not be displayed');
            }
        }
    };
};

export const actionActiveMapFetchById = (tileLayerId: number | string, flyToZoom?: number, isDrawProject?: boolean) => {
    return async (dispatch, getState) => {
        const appState: AppState = getState();
        dispatch(actionActiveMapFetching(true));
        const existingTileLayers = appState.superMapDomain.tileLayers;
        try {
            let id: number;
            if (typeof tileLayerId === 'string') {
                id = Number(tileLayerId);
            } else {
                id = tileLayerId;
            }
            let tileLayer = existingTileLayers[id];
            if (!tileLayer) {
                await ApiListings.getListing(id)
                    .then((listing) => (tileLayer = listing))
                    .catch((e) => {
                        if (/^404/.test(e.message)) {
                            toast.error('A map attached to this page has been deleted.');
                            dispatch(actionAppendDeletedMap(id));
                        } else {
                            toast.error('Something went wrong.');
                        }
                    });
            } else {
                await sleep(100);
            }

            if (tileLayer) {
                dispatch({
                    type: at.MAP_ACTIVE_TILE_LAYER_CHANGED,
                    payload: tileLayer,
                });

                dispatch({
                    type: at.MAP_FETCH_TILE_LAYER_BY_ID_SUCCESS,
                    payload: tileLayer,
                });

                if (!isDrawProject) {
                    if (tileLayer.seoTitle) {
                        UriHelper.replacePath(`/maps/${tileLayer.seoTitle}`);
                    }

                    const posParams = UriHelper.tryGetParam('pos');
                    if (posParams) {
                        const parts = posParams.split(',').map((x) => Number(x));
                        const zoom = Number(parts[2].toFixed(2));
                        const newLatLng = new LatLng(parts[0], parts[1]);

                        if (tileLayer.boundingBox.contains(newLatLng)) {
                            dispatch(actionFlyTo(newLatLng, zoom, true));
                            return;
                        }
                    }

                    if (tileLayer.listingType === ListingType.IMAGE) {
                        const bounds = GeoUtil.latLngBoundsFromPolygonWKT(tileLayer.distoredGeometry);
                        dispatch(actionFlyTo(bounds, undefined, false));
                    } else {
                        dispatch(actionFlyTo(tileLayer.boundingBox, tileLayer.minZoom, false));
                    }
                }
            }
        } finally {
            dispatch(actionActiveMapFetching(false));
        }
    };
};

export const actionAppendMap = (tileLayer: ListingDTO) => {
    return {
        type: at.MAP_FETCH_TILE_LAYER_BY_ID_SUCCESS,
        payload: tileLayer,
    };
};

export const actionDeleteFetchedMap = (tileLayerId: number) => {
    return {
        type: at.MAP_CLEAR_FETCHED_TILE_LAYER,
        payload: tileLayerId,
    };
};

export const actionAppendDeletedMap = (tileLayerId: number) => {
    return (dispatch, getState) => {
        if (!getState().superMapDomain.deletedTileLayers.includes(tileLayerId)) {
            dispatch({
                type: at.MAP_404_TILE_LAYER,
                payload: tileLayerId,
            });
        }
    };
};

export const actionClearMapById = (tileLayerId: number) => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.MAP_CLEAR_TILE_LAYER,
            payload: tileLayerId,
        });
    };
};

export const actionActiveMapFetching = (fetching: boolean) => {
    return {
        type: at.MAP_ACTIVE_TILE_LAYER_FETCHING,
        payload: fetching,
    };
};

export const actionActiveMapTileLayerLoading = (isLoading: boolean) => {
    return {
        type: at.MAP_ACTIVE_TILE_LAYER_LOADING,
        payload: isLoading,
    };
};

export const actionActiveMapCleared = () => {
    return {
        type: at.MAP_ACTIVE_TILE_LAYER_CHANGED,
        payload: undefined,
    };
};
