import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse } from 'reactstrap';
import styled from 'styled-components';
import Constants from '../../../../constants';
import Analytics from '../../../../lib/user-analytics';
import { selectMapBounds } from '../../../../store/App/selectors';

interface EmbeddedMapSettingsProps {
    configuration: Record<string, unknown>;
    setConfiguration: (configuration) => void;
    handleChangeContainerWidthCSS: (css: string) => void;
    handleChangeContainerHeightCSS: (css: string) => void;
}

enum EmbeddedBaseMaps {
    OpenStreetMaps = 'OpenStreetMaps',
    OpenElevation = 'Open Elevation',
    Satellite = 'Satellite',
    Hybrid = 'Hybrid',
    DarkBlank = 'Dark Blank',
    WhiteBlank = 'White Blank',
}

const EmbeddedMapSettings = (props: EmbeddedMapSettingsProps) => {
    const { configuration, setConfiguration } = props;
    const selectedViewportBounds = useSelector(selectMapBounds);

    const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
    const [width, setWidth] = useState<string>(Constants.EMBEDDED_MAP.DEFAULT_WIDTH);
    const [height, setHeight] = useState<string>(Constants.EMBEDDED_MAP.DEFAULT_HEIGHT);
    const [lockZoom, setLockZoom] = useState<boolean>(false);
    const [lockPosition, setLockPosition] = useState<boolean>(false);
    const [useMapBounds, setUseMapBounds] = useState<boolean>(false);
    const [slider, setSlider] = useState<boolean>(false);
    const [scale, setScale] = useState<boolean>(false);
    const [basemapControl, setBasemapControl] = useState<boolean>(false);

    // TODO: Refactor to a builder pattern with configuration typings
    useEffect(() => {
        if (useMapBounds) {
            delete configuration['bounds'];
            setConfiguration({ ...configuration });
        } else {
            setConfiguration({ ...configuration, ['bounds']: selectedViewportBounds });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useMapBounds]);

    // TODO: Refactor to a builder pattern with configuration typings
    const handleUpdateConfigurationSetting = (name: string, value: string | boolean | number) => {
        if (value) {
            setConfiguration({ ...configuration, [name]: value });
        } else {
            delete configuration[name];
            setConfiguration({ ...configuration });
        }
    };

    // TODO: Refactor to a builder pattern with configuration typings
    const handleUpdatingConfiguration = (name: string, value: string | boolean | number) => {
        // GA set on blur event for width and height
        const ignoreGASettings = ['width', 'height'];
        if (!ignoreGASettings.includes(name)) {
            Analytics.Event('Embedded Map', `Setting ${name}`, value.toString());
        }
        switch (name) {
            case 'baseMap':
                setConfiguration({ ...configuration, [name]: value });
                break;
            case 'width':
                setWidth(value as string);
                props.handleChangeContainerWidthCSS(value as string);
                break;
            case 'height':
                setHeight(value as string);
                props.handleChangeContainerHeightCSS(value as string);
                break;
            case 'useMapBounds':
                setUseMapBounds(!useMapBounds);
                handleUpdateConfigurationSetting(name, value as boolean);
                break;
            case 'lockZoom':
                setLockZoom(!lockZoom);
                handleUpdateConfigurationSetting(name, value as boolean);
                break;
            case 'slider':
                setSlider(!slider);
                handleUpdateConfigurationSetting(name, value as boolean);
                break;
            case 'scale':
                setScale(!scale);
                handleUpdateConfigurationSetting(name, value as boolean);
                break;
            case 'basemapControl':
                setBasemapControl(!basemapControl);
                handleUpdateConfigurationSetting(name, value as boolean);
                break;
            case 'lockPosition':
                setLockPosition(!lockPosition);
                handleUpdateConfigurationSetting(name, value as boolean);
                break;
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <AdvancedSettings onClick={() => setShowAdvanced(!showAdvanced)}>ADVANCED SETTINGS</AdvancedSettings>
            <CollapseContainer isOpen={showAdvanced}>
                <Fieldset>
                    <Legend>Appearance</Legend>

                    <FormGroup>
                        <Label htmlFor="width">Width:</Label>
                        <Input
                            type="text"
                            id="width"
                            name="width"
                            placeholder="Width"
                            spellCheck={false}
                            value={width}
                            onChange={(e) => {
                                handleUpdatingConfiguration(e.target.name, e.target.value);
                            }}
                            onBlur={(e) => {
                                Analytics.Event('Embedded Map', `Setting Width`, e.target.value.toString());
                            }}
                        />
                        <Hint>The width of the map as it appears in your website</Hint>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="height">Height:</Label>
                        <Input
                            type="text"
                            id="height"
                            name="height"
                            placeholder="Height"
                            spellCheck={false}
                            value={height}
                            onChange={(e) => {
                                handleUpdatingConfiguration(e.target.name, e.target.value);
                            }}
                            onBlur={(e) => {
                                Analytics.Event('Embedded Map', 'Setting height', e.target.value.toString());
                            }}
                        />
                        <Hint>The height of the map as it appears in your website</Hint>
                    </FormGroup>
                </Fieldset>

                <Fieldset>
                    <Legend>Controls</Legend>

                    <FormGroup>
                        <Label htmlFor="baseMap">Basemap:</Label>
                        <SelectField
                            name="baseMap"
                            id="baseMap"
                            onChange={(e) => {
                                handleUpdatingConfiguration(e.target.name, e.target.value);
                            }}
                        >
                            {Object.keys(EmbeddedBaseMaps).map((key) => {
                                return (
                                    <option selected={EmbeddedBaseMaps.toString() === key} key={key} value={key}>
                                        {EmbeddedBaseMaps[key]}
                                    </option>
                                );
                            })}
                        </SelectField>

                        <Hint>Change the map that you see beneath the embedded map</Hint>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="basemapControl">Basemap selection:</Label>
                        <Checkbox
                            type="checkBox"
                            id="basemapControl"
                            name="basemapControl"
                            checked={basemapControl}
                            onChange={(e) => handleUpdatingConfiguration(e.target.name, e.target.checked)}
                        />
                        <Hint>Checking this will allow the users to select the basemap with a list</Hint>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="scale">Scale:</Label>
                        <Checkbox
                            type="checkBox"
                            name="scale"
                            id="scale"
                            checked={scale}
                            onChange={(e) => handleUpdatingConfiguration(e.target.name, e.target.checked)}
                        />
                        <Hint>Checking this will add a scale to the map</Hint>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="slider">Transparency control:</Label>
                        <Checkbox
                            type="checkBox"
                            name="slider"
                            id="slider"
                            checked={slider}
                            onChange={(e) => handleUpdatingConfiguration(e.target.name, e.target.checked)}
                        />
                        <Hint>
                            Checking this will add a slider so the user can adjust the transparency of the map. Useful
                            if you want to see the difference between the map and the basemap.
                        </Hint>
                    </FormGroup>
                </Fieldset>

                <Fieldset>
                    <Legend>Position</Legend>

                    <FormGroup>
                        <Label htmlFor="useMapBounds">Use total map bounds:</Label>
                        <input
                            type="radio"
                            name="useMapBounds"
                            checked={useMapBounds}
                            onChange={(e) => handleUpdatingConfiguration(e.target.name, true)}
                        />
                        <Hint>Load showing the extents of the map</Hint>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="useMapBounds">Use my current view:</Label>
                        <input
                            type="radio"
                            name="useMapBounds"
                            checked={!useMapBounds}
                            onChange={(e) => handleUpdatingConfiguration(e.target.name, false)}
                        ></input>
                        <Hint>
                            Load showing what you are currently looking at. Use this option if you want the user's
                            attention focused on a particular part of the map.
                        </Hint>
                    </FormGroup>
                </Fieldset>

                <Fieldset>
                    <Legend>Behavior</Legend>

                    <FormGroup>
                        <Label htmlFor="lockZoom">Lock zoom:</Label>
                        <Checkbox
                            type="checkbox"
                            name="lockZoom"
                            id="lockZoom"
                            checked={lockZoom}
                            onChange={(e) => handleUpdatingConfiguration(e.target.name, e.target.checked)}
                        />
                        <Hint>Checking this will prevent the user from zooming in or out of the map</Hint>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="lockPosition">Lock position:</Label>
                        <Checkbox
                            type="checkbox"
                            name="lockPosition"
                            id="lockPosition"
                            checked={lockPosition}
                            onChange={(e) => handleUpdatingConfiguration(e.target.name, e.target.checked)}
                        />
                        <Hint>Checking this will prevent the user from panning around the map</Hint>
                    </FormGroup>
                </Fieldset>
            </CollapseContainer>
        </React.Fragment>
    );
};

export default EmbeddedMapSettings;

const CollapseContainer = styled(Collapse)`
    margin: 12px;
`;

const Fieldset = styled.fieldset`
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 1px;
    color: rgba(255, 255, 255, 0.6);
    padding: 8px;
    margin-bottom: 12px;
`;

const Legend = styled.legend`
    width: auto;
    color: #eed926;
    margin: 0 8px 0 0;
    padding: 0 4px;
    font-size: 1.3rem;
`;

const Label = styled.label`
    min-width: 170px;
    color: white;
`;

const Hint = styled.div`
    opacity: 0;
    background: #eee;
    position: absolute;
    display: inline-block;
    margin: 3px 0 0 5px;
    padding: 3px;

    max-width: 240px;
    user-select: none;
    border: solid 1px #ccc;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    color: #555;
    transition-delay: 2s;
    transition: opacity 0.2s;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.05);

    &::before {
        content: '';
        border-color: #eee;
        display: block;
        width: 0;
        height: 0;
        position: absolute;

        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #eee;
        left: -8px;

        top: 5px;
    }
`;

const FormGroup = styled.div`
    display: block;
    width: 65%;
    &:hover ${Hint} {
        opacity: 1;
        pointer-events: none;
        transition-delay: 0.5s;
    }
`;

const SelectField = styled.select`
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent !important;
    font-size: 1rem;
    outline: none;
    color: rgba(255, 255, 255, 0.6);
    width: 150px;
    padding: 5px;
    cursor: pointer;

    option {
        color: rgba(255, 255, 255, 0.6);
        background-color: rgba(0, 0, 0, 0.8);
    }

    :hover {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }
`;

const AdvancedSettings = styled.a`
    text-decoration: underline !important;
    padding: 12px 0;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    color: rgba(255, 255, 255, 0.6) !important;
`;

const Input = styled.input`
    width: 150px;
    height: 31px !important;
    padding: 0px 12px;
    margin: 0 8px 0 0;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent !important;
    font-size: 16px;
    outline: none;
    color: rgba(255, 255, 255, 0.6) !important;
    cursor: text;

    :-webkit-autofill {
        :hover {
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
            -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        }
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(255, 255, 255, 0.3) !important;
    }

    :hover {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }

    :focus {
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }

    option {
        color: rgba(255, 255, 255, 0.6);
        background-color: rgba(0, 0, 0, 0.8);
    }
`;

const Checkbox = styled.input`
    cursor: pointer;
    margin: 10px 8px 10px 0;
`;
