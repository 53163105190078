import L, { LatLngBounds } from 'leaflet';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiFeaturedContent from '../../../../api/api-featured-content';
import ApiListings, { GetListingsParams } from '../../../../api/api-listings';
import GeoUtil from '../../../../lib/geo-util';
import SoarHelper from '../../../../lib/soar-helper';
import { selectMapBounds, selectMapMoveEnd, selectMapZoom } from '../../../../store/App/selectors';
import { selectSelectedSearchTerm } from '../../../../store/Map/MapSelection/selectors';
import { LISTING_COUNT } from '../../../LandingPage/map-count-ticker';
import { attachChipToListingDTO, ListingDTOWithChip } from './listing-card-chip';
import MapSearchDrawAOIResults from './map-search-draw-aoi-results';
import MapSearchDrawListingCount from './map-search-draw-listing-count';

const RESULT_LIMIT = 25; // Api default
const LOAD_MORE_LIMIT = 20;
const MAP_MOVE_DEBOUNCE = 250;
const WORLD_BOUNDS = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180));
const WORLD_BOUNDS_ZOOM_CUTOFF = 3;

const MapSearchDraw = () => {
    const [listings, setListings] = useState<ListingDTOWithChip[] | undefined>();
    const [firstLoadListings, setFirstLoadListings] = useState<ListingDTOWithChip[] | undefined>();
    const [listingCount, setListingCount] = useState<number | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [featuredListings, setFeaturedListings] = useState<ListingDTOWithChip[]>([]);
    const [newestListings, setNewestListings] = useState<ListingDTOWithChip[]>([]);
    const [globalListings, setGlobalListings] = useState<ListingDTOWithChip[]>([]);

    const searchTerm = useSelector(selectSelectedSearchTerm);
    const mapZoom = useSelector(selectMapZoom);
    const mapBounds = useSelector(selectMapBounds);
    const mapMoveEnd = useSelector(selectMapMoveEnd);

    useEffect(() => {
        loadFirstListings();
    }, []);

    useEffect(() => {
        if (mapZoom && mapZoom < WORLD_BOUNDS_ZOOM_CUTOFF) {
            setListings(firstLoadListings);
        }
    }, [mapZoom, firstLoadListings]);

    const loadFirstListings = () => {
        const aoiWKT = GeoUtil.latLngBoundsToWKT(WORLD_BOUNDS);

        if (SoarHelper.isSoar()) {
            ApiFeaturedContent.getHottestMaps().then((res) => {
                const results: ListingDTOWithChip[] = res.slice(0, 5);
                const featuredResults = results.map((r) => {
                    r.chip = 'featured';
                    return r;
                });
                setFeaturedListings(featuredResults);
            });

            ApiListings.getListings({ limit: 6 }).then((res) => {
                const results: ListingDTOWithChip[] = res.listings.slice(0, 5);
                const newResults = results.map((r) => {
                    r.chip = 'new';
                    return r;
                });
                setNewestListings(newResults);
            });
        }

        const params: GetListingsParams = {
            aoi: aoiWKT,
            limit: RESULT_LIMIT,
            offset: 0,
        };
        ApiListings.getListings(params)
            .then((res) => {
                // Use true count from the server
                //setFirstLoadListingsCount(res.locationTotal);

                if (res.listings.length > 0) {
                    // Use the estimate count from the landing page, that includes satellite data
                    if (SoarHelper.isSoar()) {
                        setListingCount(LISTING_COUNT);
                    } else {
                        setListingCount(res.total);
                    }
                    const results: ListingDTOWithChip[] = res.listings;
                    const categoryResults: ListingDTOWithChip[] = results.map((r) => {
                        return attachChipToListingDTO(r);
                    });
                    setGlobalListings(categoryResults);
                }
            })
            .catch((error) => {
                if (!error.toString().includes('Cancel')) {
                    console.log('Error fetching listings:', error);
                }
            });
    };

    useEffect(() => {
        if (
            featuredListings.length > 0 &&
            newestListings.length > 0 &&
            globalListings.length > 0 &&
            SoarHelper.isSoar()
        ) {
            setFirstLoadListings([...featuredListings, ...newestListings, ...globalListings]);
        } else if (globalListings.length > 0) {
            setFirstLoadListings(globalListings);
        }
    }, [featuredListings, newestListings, globalListings]);

    const cancelFetchListings = () => ApiListings.cancelGetListings('AOI Changed');

    const fetchListings = useCallback(
        async (offset: number, loadMoreLimit?: number) => {
            if (!mapZoom || !mapBounds) return;

            if (mapZoom < WORLD_BOUNDS_ZOOM_CUTOFF && !searchTerm && offset === 0) {
                if (!firstLoadListings) {
                    loadFirstListings();
                    return;
                } else {
                    setListings(firstLoadListings);
                    if (SoarHelper.isSoar()) {
                        setListingCount(LISTING_COUNT);
                    } else {
                        setListingCount(firstLoadListings.length);
                    }
                    return;
                }
            }

            if (offset === 0) {
                setListings(undefined);
                setListingCount(undefined);
            }

            try {
                if (!process.env.STORYBOOK) {
                    cancelFetchListings();
                }
                setIsLoading(true);

                const aoiWKT = GeoUtil.latLngBoundsToWKT(mapBounds as LatLngBounds);
                const params: GetListingsParams = {
                    aoi: aoiWKT,
                    limit: loadMoreLimit ? loadMoreLimit : RESULT_LIMIT,
                    offset: offset,
                    keywords: searchTerm,
                };
                const data = await ApiListings.getListings(params);

                if (data?.listings) {
                    if (!loadMoreLimit) {
                        const categoryResults: ListingDTOWithChip[] = data.listings.map((r) => {
                            return attachChipToListingDTO(r);
                        });
                        if (categoryResults.length > 0) {
                            setListings(categoryResults);
                        }
                    } else {
                        const categoryResults: ListingDTOWithChip[] = data.listings.map((r) => {
                            return attachChipToListingDTO(r);
                        });
                        if (categoryResults.length > 0) {
                            setListings((prevListings) => [...(prevListings ?? []), ...categoryResults]);
                        }
                    }

                    if (data?.listings.length > 0) {
                        if (mapZoom < WORLD_BOUNDS_ZOOM_CUTOFF && !searchTerm) {
                            setListingCount(LISTING_COUNT);
                        } else {
                            setListingCount(data.total);
                        }
                    }
                }
            } catch (error) {
                if (!error.toString().includes('Cancel')) {
                    console.log('Error fetching listings:', error);
                }
            } finally {
                setTimeout(() => setIsLoading(false), 300);
            }
        },
        [mapZoom, mapBounds, searchTerm, firstLoadListings]
    );

    useEffect(() => {
        fetchListings(0);
    }, [fetchListings]);

    const debouncedHandleMapMove = useMemo(() => debounce(() => fetchListings(0), MAP_MOVE_DEBOUNCE), [fetchListings]);

    useEffect(() => {
        if (mapMoveEnd) {
            debouncedHandleMapMove();
        }

        return () => {
            debouncedHandleMapMove.cancel();
        };
    }, [mapMoveEnd, debouncedHandleMapMove]);

    const handleLoadMore = debounce(() => {
        fetchListings(listings?.length ?? 0, LOAD_MORE_LIMIT);
    }, MAP_MOVE_DEBOUNCE);

    return (
        <MapSearchDrawContainer>
            <MapSearchDrawListingCount listingLocationCount={listingCount} />
            <MapSearchResultsDivider />
            <MapSearchDrawAOIResults
                listings={listings}
                listingsCount={listingCount || 0}
                isLoading={isLoading}
                onLoadMore={handleLoadMore}
            />
        </MapSearchDrawContainer>
    );
};

export default MapSearchDraw;

const MapSearchDrawContainer = styled.div`
    margin: 0px 0px 0px 7px;
`;

const MapSearchResultsDivider = styled.div`
    height: 1px;
    background-color: transparent;
    margin-top: 10px;
`;
