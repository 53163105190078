import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface CollapsibleTextProps {
    children: React.ReactNode;
    maxHeight?: number;
    toggleTextMore?: string;
    toggleTextLess?: string;
}

const DEFAULT_TEXT_HEIGHT = 80;

const CollapsibleText = (props: CollapsibleTextProps) => {
    const [showLongText, setShowLongText] = useState(true);
    const [showLongTextToggle, setShowLongTextToggle] = useState(false);

    const breakpointHeight = props.maxHeight ?? DEFAULT_TEXT_HEIGHT;

    useEffect(() => {
        // Check if the profile description is too long to display
        const collapsibleText = document?.querySelector('.collapsible-text');
        if (collapsibleText && collapsibleText.scrollHeight > breakpointHeight) {
            setShowLongText(false);
            setShowLongTextToggle(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CollapsibleTextContainer>
            <CollapsibleTextContent className="collapsible-text" showAll={showLongText}>
                {props.children}
            </CollapsibleTextContent>
            {showLongTextToggle && (
                <CollapsibleTextToggle onClick={() => setShowLongText(!showLongText)}>
                    {showLongText ? props.toggleTextLess ?? 'Read less' : props.toggleTextMore ?? 'Read more'}
                </CollapsibleTextToggle>
            )}
        </CollapsibleTextContainer>
    );
};

export default CollapsibleText;

const CollapsibleTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const CollapsibleTextContent = styled.div<{ showAll: boolean }>`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${(props) => (props.showAll ? 'none' : 3)};
    line-clamp: ${(props) => (props.showAll ? 'none' : 3)};
    text-overflow: ellipsis;
    overflow: hidden;

    margin-bottom: 8px;

    * {
        color: ${(props) => props.theme.color.white};
        font-size: ${(props) => props.theme.fontSize.normal};
    }

    p:last-child {
        margin-bottom: 0;
    }
`;

const CollapsibleTextToggle = styled.button`
    background: transparent;
    border: none;

    padding: 8px 16px;
    color: ${(props) => props.theme.color.white};
    font-weight: 700;

    width: auto;
    align-self: flex-end;

    &:hover,
    &:focus {
        outline: none;
    }
`;
