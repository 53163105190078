import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    selectUserListings,
    selectUserListingsLoading,
    selectUserProfile,
    selectUserProfileLoading,
} from '../../../store/Map/UserProfile/selectors';
import {
    actionClearUserProfileWithListings,
    actionFetchUserProfileWithListings,
} from '../../../store/Map/UserProfile/actions';

import MobileViewHeader from './View/mobile-header';
import MobileViewDetails from './View/mobile-details';
import MobileViewListings from './View/mobile-listings';
import { ContentLoading } from './Shared/panels';

export interface MobileProfileViewProps {
    profileId: string;
    onClose?: () => void;
}

const MobileProfileView = (props: MobileProfileViewProps) => {
    const userProfile = useSelector(selectUserProfile);
    const userProfileLoading = useSelector(selectUserProfileLoading);
    const userListings = useSelector(selectUserListings);
    const userListingsLoading = useSelector(selectUserListingsLoading);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(actionClearUserProfileWithListings());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.profileId === '') {
            return;
        }

        if (!userProfile || (userProfile.userId && userProfile.userId !== props.profileId)) {
            dispatch(actionFetchUserProfileWithListings(props.profileId));
        }
    }, [dispatch, userProfile, props.profileId]);

    const handleClose = () => {
        dispatch(actionClearUserProfileWithListings());

        props.onClose && props.onClose();
    };

    if (userProfileLoading) {
        return <ContentLoading />;
    }

    if (!userProfile || props.profileId === '') {
        return <React.Fragment />;
    }

    return (
        <ProfileViewContainer>
            <MobileViewHeader profile={userProfile} onClose={handleClose} />
            <MobileViewDetails profile={userProfile} listings={userListings} />
            <MobileViewListings listings={userListings} listingsLoading={userListingsLoading} />
        </ProfileViewContainer>
    );
};

export default MobileProfileView;

const ProfileViewContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    overflow-y: auto;

    height: calc(100vh - 60px);
`;
