import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface MobileDropdownProps {
    isOpen: boolean;
    toggle: () => void;
    dropdownIcon: React.ReactNode | string;
    children: React.ReactNode;
    containerHeight?: string;
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
}

const ANIMATION_DURATION = 200; //ms

/**
 * Reusable dropdown component with standardized style and animation
 * @param isOpen : open dropdown externally
 * @param toggle : toggle function
 * @param dropdownIcon : ReactNode - icon for dropdown
 * @param children: ReactNode - content to be displayed in dropdown
 * @param containerHeight: string optional - preferred to prevent animation tearing
 * @param top - string optional - positioning dropdown container
 * @param bottom - string optional - positioning dropdown container
 * @param left - string optional - positioning dropdown container
 * @param right - string optional - positioning dropdown container
 *
 */
const MobileDropdown = (props: MobileDropdownProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        setIsDialogOpen(props.isOpen);
    }, [props.isOpen]);

    return (
        <React.Fragment>
            <div onClick={props.toggle}>{props.dropdownIcon}</div>

            {isDialogOpen ? (
                <React.Fragment>
                    <ScreenFillContainer onClick={props.toggle} />
                    <DropdownContent
                        containerheight={props.containerHeight}
                        top={props.top}
                        bottom={props.bottom}
                        left={props.left}
                        right={props.right}
                    >
                        {props.children}
                    </DropdownContent>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};

export default MobileDropdown;

const ScreenFillContainer = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.3);
    z-index: 99999;
`;

const OpenAnimation = (height: string | undefined) => keyframes`
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }

  75% {
    opacity : 1;
  }

  100% {
    width: 80vw;
    height: ${height ? height : '80vh'};
  }
`;

interface MobileDropdownStyleProps {
    containerheight?: string;
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
}

const DropdownContent = styled.div<MobileDropdownStyleProps>`
    animation: ${ANIMATION_DURATION}ms linear 0s ${(props) => OpenAnimation(props.containerheight)};
    z-index: 99999;
    position: absolute;
    right: ${(props) => (props.right ? props.right : '')};
    left: ${(props) => (props.left ? props.left : '')};
    top: ${(props) => (props.top ? props.top : '')};
    bottom: ${(props) => (props.bottom ? props.bottom : '')};
    width: 80vw;
    max-width: 430px;
    height: auto;
    max-height: ${(props) => (props.containerheight ? props.containerheight : '80vh')};
    overflow-x: hidden;
    overflow-y: auto;

    background: rgba(0, 0, 0, 0.75);
    border-radius: 6px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.25);
`;
