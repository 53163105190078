import { SentinelFeature } from '../store/Map/Sentinel/model';
import { LatLngBounds, LatLng } from 'leaflet';
import ReactGA from 'react-ga4';
import Constants from '../constants';
import { SentinelMetadata } from '../store/Map/Sentinel/model';
import { AnalyticsAction, SatelliteProvider } from '../api/model';
import GeoUtil from './geo-util';
import ApiAnalytics from '../api/api-analytics';
import axios from 'axios';
import { DateRange } from '../components/Drawer/Satellites/Sentinel/sentinel-filter';
import UriHelper from './uri-helper';

const moment = require('moment');

/*
 *  Sentinel WFS only allows 2500x2500 pixel images.  We need to calculate what can be
 *  asked for based on the geometry
 */
export function pixelResolutionForBounds(bounds: LatLngBounds): string {
    const MAX_SENTINEL_IMAGE_SIZE = 2500;

    const widthMeters = GeoUtil.widthKilometers(bounds) * 1000;
    const heightMeters = GeoUtil.heightKilometers(bounds) * 1000;

    const imageWidthFor10m = Math.round(widthMeters / 10);
    const imageHeightFor10m = Math.round(heightMeters / 10);

    if (imageWidthFor10m < MAX_SENTINEL_IMAGE_SIZE && imageHeightFor10m < MAX_SENTINEL_IMAGE_SIZE) {
        return '10m';
    }

    const imageWidthFor20m = Math.round(widthMeters / 20);
    const imageHeightFor20m = Math.round(heightMeters / 20);
    if (imageWidthFor20m < MAX_SENTINEL_IMAGE_SIZE && imageHeightFor20m < MAX_SENTINEL_IMAGE_SIZE) {
        return '20m';
    }

    const imageWidthFor50m = Math.round(widthMeters / 50);
    const imageHeightFor50m = Math.round(heightMeters / 50);
    if (imageWidthFor50m < MAX_SENTINEL_IMAGE_SIZE && imageHeightFor50m < MAX_SENTINEL_IMAGE_SIZE) {
        return '50m';
    }

    return '100m';
}

let CANCEL_TOKEN = axios.CancelToken.source();

export function getFeatures(
    bbox: LatLngBounds,
    dateStart: Date,
    dateEnd: Date,
    overlay: SentinelMetadata
): Promise<SentinelFeature[]> {
    bbox = GeoUtil.wrapBoundingBox(bbox);

    const northEast = new LatLng(bbox.getNorth(), bbox.getEast());
    const southWest = new LatLng(bbox.getSouth(), bbox.getWest());

    const northEastXY = GeoUtil.latLngToEPSG3857(northEast);
    const southWestXY = GeoUtil.latLngToEPSG3857(southWest);

    const bboxString = northEastXY[0] + ',' + northEastXY[1] + ',' + southWestXY[0] + ',' + southWestXY[1];
    const sentinelStartDate = formatDate(dateStart);
    const sentinelEndDate = formatDate(dateEnd);
    const sentinelTime = sentinelStartDate + '/' + sentinelEndDate + '/P1D';

    let pixelResolution;
    if (overlay === Constants.OVERLAY_DATA.LANDSAT.LANDSAT_8) {
        pixelResolution = `${Constants.OVERLAY_DATA.LANDSAT.LANDSAT_8.resolution.toString()}m`;
    } else {
        pixelResolution = pixelResolutionForBounds(bbox);
    }

    let fisURL: string;
    switch (overlay.key) {
        case '1_TRUE_COLOR':
        case 'LANDSAT_8':
            fisURL = Constants.SENTINEL.LANDSAT_8_STATISTICAL_API.replace('${bbox}', bboxString).replace(
                '${date}',
                sentinelTime
            );
            break;

        case 'SENTINEL_1_GRD':
        case 'SENTINEL_1_RADAR':
            fisURL = Constants.SENTINEL.SENTINEL_1_STATISTICAL_API.replace('${bbox}', bboxString).replace(
                '${date}',
                sentinelTime
            );
            break;

        default:
            fisURL = Constants.SENTINEL.SENTINEL_2_STATISTICAL_API.replace('${bbox}', bboxString).replace(
                '${date}',
                sentinelTime
            );
            break;
    }

    ApiAnalytics.postAnalyticsSatellite(
        fisURL,
        SatelliteProvider.SINERGISE,
        overlay.satelliteAnalytics,
        AnalyticsAction.SEARCH
    );

    return new Promise<SentinelFeature[]>((resolve, reject) => {
        axios
            .get(fisURL, { cancelToken: CANCEL_TOKEN.token })
            .then((response) => {
                return response.data;
            })
            .then((json) => {
                return json.C0;
            })
            .then((statisticalData) => {
                const validDates: any[] = []; // eslint-disable-line

                if (statisticalData === undefined) {
                    reject('No data available.  Please select a different area.');
                    return [];
                }
                statisticalData.forEach((statisticPoint) => {
                    const max = statisticPoint.basicStats.max;
                    const min = statisticPoint.basicStats.min;
                    const mean = statisticPoint.basicStats.mean;
                    const stDev = statisticPoint.basicStats.stDev;

                    if (max !== 'NaN' && min !== 'NaN' && mean !== 'NaN' && stDev !== 'NaN') {
                        validDates.push(statisticPoint.date);
                    }
                });
                if (!validDates.length) {
                    console.error('Sentinel request failure data:');
                    console.error(statisticalData);
                    console.error(`Sentinel failed URL: ${fisURL}`);
                    ReactGA.event({
                        category: 'Sentinel',
                        action: 'Failed FIS Lookup',
                        label: 'Data: ' + JSON.stringify(statisticalData) + ' URL: ' + fisURL,
                    });
                    reject('No data available.  Please select a different data range.');
                    return [];
                }
                return validDates;
            })
            .then((validDates) => {
                const sentinelFeatures: SentinelFeature[] = validDates.map((date, index) => {
                    const dateStr = date + '/' + date;
                    const SHARE_PREVIEW_SIZE = '450';

                    const sentinelFeature: SentinelFeature = {
                        bbox: bbox,
                        date: date,
                        layer: overlay.key,
                        layerKey: overlay.key,
                        satellite: overlay.satellite,
                        satelliteAnalytics: overlay.satelliteAnalytics,
                        previewUrl: previewUrlForFeature(bboxString, dateStr, overlay.key),
                        sharelinkPreviewUrl: sharePreviewUrlForFeature(
                            bboxString,
                            dateStr,
                            overlay.key,
                            SHARE_PREVIEW_SIZE
                        ),
                        highResolutionPreviewUrl: fullResolutionUrlForFeature(
                            bboxString,
                            dateStr,
                            overlay.key,
                            pixelResolution
                        ),
                        downloadUrl: downloadUrlForFeature(bboxString, dateStr, overlay.key, pixelResolution),
                        resolution: pixelResolution,
                        id: index,
                    };

                    return sentinelFeature;
                });
                return sentinelFeatures;
            })
            .then((features) => {
                resolve(features);
            })
            .catch(({ response }) => {
                // If the Axios request is intercepted and cancelled it becomes undefined and is handled here
                if (!response) {
                    return [];
                }

                // 429 error is rate limit error, best to return a string response
                if (response.status === 429) {
                    const rateLimitError = 'Sentinel service is currently in high demand. Please try again later.';
                    reject(rateLimitError);
                }

                // 503 - sentinel error message  "No server is available to handle this request."
                if (response.status === 503) {
                    const rateLimitError = 'Sentinel service is currently in high demand. Please try again later.';
                    reject(rateLimitError);
                }

                // A catch all for when the request executed but the response is not ok, occurs with LANDSAT 429 error
                // In this case the message is not one that is suitable to present to the user so a generic response used.
                if (!response.data.ok && response.status === 429) {
                    const genericResponse = 'Service is under load, try again later';
                    reject(genericResponse);
                }

                if (response.status === 400 || !(response.statusText === 'OK' || response.data.ok === true)) {
                    const error = response.data;
                    if (error.includes('ServiceExceptionReport')) {
                        reject('Sorry, this service is currently unavailable. Please try again later');
                    }
                    if (error.includes('large')) {
                        reject('Search area too large. Please choose a smaller Area of Interest (AOI).');
                    } else {
                        // Keeping it separate for readability incase the response ever changes.
                        // Get the XML response and strips out the error message
                        const indexBeginsAt = error.indexOf('Output');
                        const indexEndsAt = error.indexOf('large!');
                        const errorResponse = error.slice(indexBeginsAt, indexEndsAt + 7);
                        // Response updated as currently sentinel error will responds
                        // "Should be at most 2500 x 2500 pixels"
                        // but this is not the case
                        reject(errorResponse.concat('Search area too large.  Please zoom in.'));
                    }
                }
                return [];
            });
    });
}

export function cancelFeatures(cancelReason: string) {
    // Allow a reason for cancellation to be added
    CANCEL_TOKEN.cancel(cancelReason);
    // Generate a new token each time a request is cancelled;
    generateCancelToken();
}

function generateCancelToken() {
    CANCEL_TOKEN = axios.CancelToken.source();
}

export function downloadUrlForFeature(bboxString: string, date: string, layer: string, resolution: string): string {
    switch (layer) {
        case 'TRUE_COLOR':
            return Constants.SENTINEL.SENTINEL_2_TRUE_COLOR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution)
                .replace('format=image%2Fpng', 'format=image%2Ftiff;depth=16');

        case 'FALSE_COLOR':
            return Constants.SENTINEL.SENTINEL_2_FALSE_COLOR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution)
                .replace('format=image%2Fpng', 'format=image%2Ftiff;depth=16');

        case 'GEOLOGY':
            return Constants.SENTINEL.SENTINEL_2_GEOLOGY.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution)
                .replace('format=image%2Fpng', 'format=image%2Ftiff;depth=16');

        case 'NDVI':
            return Constants.SENTINEL.SENTINEL_2_NDVI.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution)
                .replace('format=image%2Fpng', 'format=image%2Ftiff;depth=16');

        case 'DEM':
            return Constants.SENTINEL.SENTINEL_2_DEM.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution)
                .replace('format=image%2Fpng', 'format=image%2Ftiff;depth=16');

        case '1_TRUE_COLOR':
        case 'LANDSAT_8':
            return Constants.SENTINEL.LANDSAT_8_TRUE_COLOR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution)
                .replace('format=image%2Fpng', 'format=image%2Ftiff;depth=16');

        case 'SENTINEL_1_GRD':
        case 'SENTINEL_1_RADAR':
            return Constants.SENTINEL.SENTINEL_1_RADAR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution)
                .replace('format=image%2Fpng', 'format=image%2Ftiff;depth=16');

        default:
            throw new Error('Invalid layer type');
    }
}

export function previewUrlForFeature(bboxString: string, date: string, layer: string): string {
    switch (layer) {
        case 'TRUE_COLOR':
        case 'TRUE_COLOR_PREVIEW':
            return Constants.SENTINEL.SENTINEL_2_TRUE_COLOR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', 'width=100&height=100');

        case 'FALSE_COLOR':
            return Constants.SENTINEL.SENTINEL_2_FALSE_COLOR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', 'width=100&height=100');

        case 'GEOLOGY':
            return Constants.SENTINEL.SENTINEL_2_GEOLOGY.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', 'width=100&height=100');

        case 'NDVI':
        case '3_NDVI':
            return Constants.SENTINEL.SENTINEL_2_NDVI.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', 'width=100&height=100');

        case 'DEM':
        case 'DEM___COLOR':
            return Constants.SENTINEL.SENTINEL_2_DEM.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', 'width=100&height=100');

        case 'LANDSAT_8':
        case '1_TRUE_COLOR':
            return Constants.SENTINEL.LANDSAT_8_TRUE_COLOR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', 'width=100&height=100');

        case 'SENTINEL_1_RADAR':
        case 'SENTINEL_1_GRD':
            return Constants.SENTINEL.SENTINEL_1_RADAR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', 'width=100&height=100');

        default:
            throw new Error('Invalid layer type');
    }
}

function sharePreviewUrlForFeature(bboxString: string, date: string, layer: string, size: string): string {
    switch (layer) {
        case 'TRUE_COLOR':
        case 'TRUE_COLOR_PREVIEW':
            return Constants.SENTINEL.SENTINEL_2_TRUE_COLOR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', `width=${size}&height=${size}`);

        case 'FALSE_COLOR':
            return Constants.SENTINEL.SENTINEL_2_FALSE_COLOR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', `width=${size}&height=${size}`);

        case 'GEOLOGY':
            return Constants.SENTINEL.SENTINEL_2_GEOLOGY.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', `width=${size}&height=${size}`);

        case 'NDVI':
        case '3_NDVI':
            return Constants.SENTINEL.SENTINEL_2_NDVI.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', `width=${size}&height=${size}`);

        case 'DEM':
        case 'DEM___COLOR':
            return Constants.SENTINEL.SENTINEL_2_DEM.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', `width=${size}&height=${size}`);

        case 'LANDSAT_8':
        case '1_TRUE_COLOR':
            return Constants.SENTINEL.LANDSAT_8_TRUE_COLOR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', `width=${size}&height=${size}`);

        case 'SENTINEL_1_RADAR':
        case 'SENTINEL_1_GRD':
            return Constants.SENTINEL.SENTINEL_1_RADAR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('resX=${resX}&resY=${resY}', `width=${size}&height=${size}`);

        default:
            throw new Error('Invalid layer type');
    }
}

export function fullResolutionUrlForFeature(
    bboxString: string,
    date: string,
    layer: string,
    resolution: string
): string {
    switch (layer) {
        case 'TRUE_COLOR':
        case 'TRUE_COLOR_PREVIEW':
            return Constants.SENTINEL.SENTINEL_2_TRUE_COLOR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution);

        case 'FALSE_COLOR':
            return Constants.SENTINEL.SENTINEL_2_FALSE_COLOR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution);

        case 'GEOLOGY':
            return Constants.SENTINEL.SENTINEL_2_GEOLOGY.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution);

        case 'NDVI':
        case '3_NDVI':
            return Constants.SENTINEL.SENTINEL_2_NDVI.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution);

        case 'DEM':
        case 'DEM___COLOR':
            return Constants.SENTINEL.SENTINEL_2_DEM.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution);

        case 'LANDSAT_8':
        case '1_TRUE_COLOR':
            return Constants.SENTINEL.LANDSAT_8_TRUE_COLOR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution);

        case 'SENTINEL_1_RADAR':
        case 'SENTINEL_1_GRD':
            return Constants.SENTINEL.SENTINEL_1_RADAR.replace('${bbox}', bboxString)
                .replace('${date}', date)
                .replace('${date}', date)
                .replace('${resX}', resolution)
                .replace('${resY}', resolution);

        default:
            throw new Error('Invalid layer type');
    }
}

function formatDate(date: Date): string {
    return date.toISOString().substr(0, 10);
}

export function compareSentinelFeatures(f1?: SentinelFeature, f2?: SentinelFeature): boolean {
    const FIXED_DECIMAL = 6;
    if (f1 && f2) {
        return (
            f1.date.toString() === moment(f2.date).format('YYYY-MM-DD') &&
            f1.layerKey === f2.layerKey &&
            f1.satellite === f2.satellite &&
            f1.bbox.getNorthEast().lat.toFixed(FIXED_DECIMAL) === f2.bbox.getNorthEast().lat.toFixed(FIXED_DECIMAL) &&
            f1.bbox.getNorthEast().lng.toFixed(FIXED_DECIMAL) === f2.bbox.getNorthEast().lng.toFixed(FIXED_DECIMAL) &&
            f2.bbox.getSouthWest().lat.toFixed(FIXED_DECIMAL) === f2.bbox.getSouthWest().lat.toFixed(FIXED_DECIMAL) &&
            f2.bbox.getSouthWest().lng.toFixed(FIXED_DECIMAL) === f2.bbox.getSouthWest().lng.toFixed(FIXED_DECIMAL)
        );
    } else if (!f1 && !f2) {
        return true;
    } else {
        return false;
    }
}

export const getDateFromShareLink = (requestedDate): DateRange => {
    const beginning = moment(requestedDate);
    const selectedDate = beginning;
    const startDate = selectedDate.clone().subtract(1, 'months');

    const dateRange: DateRange = {
        startDate: startDate,
        endDate: selectedDate,
        displayText: selectedDate.format('MMMM YYYY'),
    };

    return dateRange;
};

export const getLayerFromShareLink = (key: string) => {
    switch (key) {
        case 'TRUE_COLOR':
            return Constants.OVERLAY_DATA.SENTINEL.TRUE_COLOR;
        case 'FALSE_COLOR':
            return Constants.OVERLAY_DATA.SENTINEL.FALSE_COLOR;
        case 'GEOLOGY':
            return Constants.OVERLAY_DATA.SENTINEL.GEOLOGY;
        case 'NDVI':
            return Constants.OVERLAY_DATA.SENTINEL.NDVI;
        case 'SENTINEL_1_RADAR':
        case 'SENTINEL_1_GRD':
            return Constants.OVERLAY_DATA.SENTINEL.SENTINEL_1_RADAR;
        default:
            return Constants.OVERLAY_DATA.SENTINEL.TRUE_COLOR;
    }
};

export const addSentinelParamsToUrl = (shareFeature: SentinelFeature) => {
    const northEast = new LatLng(shareFeature.bbox.getNorth(), shareFeature.bbox.getEast());
    const southWest = new LatLng(shareFeature.bbox.getSouth(), shareFeature.bbox.getWest());

    const northEastXY = GeoUtil.latLngToEPSG3857(northEast);
    const southWestXY = GeoUtil.latLngToEPSG3857(southWest);

    const bbox = northEastXY[0] + ',' + northEastXY[1] + ',' + southWestXY[0] + ',' + southWestXY[1];

    const urlParams = {
        service: 'WMS',
        request: 'GetMap',
        bbox: bbox,
        time: moment(shareFeature.date).format('YYYY-MM-DD'),
        layerKey: shareFeature.layerKey,
        layers: shareFeature.layer,
        title: shareFeature.satellite,
        image_data: shareFeature.previewUrl,
    };
    UriHelper.addParametersToUri(urlParams);
};

export const addSatelliteGeoJsonParamsToUrl = (shareFeature) => {
    const northEastXY = GeoUtil.latLngToEPSG3857(shareFeature.bbox._northEast);
    const southWestXY = GeoUtil.latLngToEPSG3857(shareFeature.bbox._southWest);

    const bbox = northEastXY[0] + ',' + northEastXY[1] + ',' + southWestXY[0] + ',' + southWestXY[1];

    const urlParams = {
        service: 'WMS',
        request: 'GetMap',
        bbox: bbox,
        time: moment(shareFeature.date).format('YYYY-MM-DD'),
        layerKey: shareFeature.layerKey,
        layers: shareFeature.layer,
        title: shareFeature.satellite,
        image_data: shareFeature.previewUrl,
    };
    UriHelper.addParametersToUri(urlParams);
};

export const createSentinelShareUrl = (shareFeature: SentinelFeature): string => {
    const northEast = new LatLng(shareFeature.bbox.getNorth(), shareFeature.bbox.getEast());
    const southWest = new LatLng(shareFeature.bbox.getSouth(), shareFeature.bbox.getWest());

    const northEastXY = GeoUtil.latLngToEPSG3857(northEast);
    const southWestXY = GeoUtil.latLngToEPSG3857(southWest);

    const bbox = `${northEastXY[0]},${northEastXY[1]},${southWestXY[0]},${southWestXY[1]}`;

    const urlParams = new URLSearchParams({
        service: 'WMS',
        request: 'GetMap',
        bbox,
        time: moment(shareFeature.date).format('YYYY-MM-DD'),
        layerKey: shareFeature.layerKey,
        layers: shareFeature.layer,
        title: shareFeature.satellite,
        image_data: shareFeature.previewUrl,
    });

    const baseUrl = new URL(window.location.href);
    return `${baseUrl.origin}${baseUrl.pathname}?${urlParams.toString()}`;
};
