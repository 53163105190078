import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    selectSentinelError,
    selectSentinelFeatures,
    selectSentinelFeaturesLoading,
    selectSentinelMapZoom,
    selectSentinelSelectedAOI,
    selectSentinelSelectedFeature,
} from '../../../store/Map/Sentinel/selectors';
import { actionBeginMobileSentinelSearch } from '../../../store/Map/Mobile/actions';
import SentinelFilter, { DateRange, last30Days } from '../../Drawer/Satellites/Sentinel/sentinel-filter';
import SentinelItem from '../../Drawer/Satellites/Sentinel/sentinel-item';
import MobileSatelliteLoading from './mobile-satellite-loading';
import { SentinelMetadata } from '../../../store/Map/Sentinel/model';
import Constants from '../../../constants';
import {
    actionSentinelClearFeatures,
    actionSentinelCloseDownload,
    actionSentinelCloseShare,
    actionSentinelFetchFeatures,
    actionSentinelResetSelectedFeature,
    actionSentinelResetSelectedAOI,
} from '../../../store/Map/Sentinel/actions';
import { useDispatch, useSelector } from 'react-redux';
import { StyledButton } from '../../Shared/styled-button';
import UriHelper from '../../../lib/uri-helper';
import { getDateFromShareLink, getLayerFromShareLink, pixelResolutionForBounds } from '../../../lib/sentinel-service';
import { BottomSheetState } from '../BottomSheet/bottom-sheet';
import MobileSentinelSearchResult from './mobile-sentinel-search-result';
import UserHelper from '../../../lib/user-helper';
import { selectMapBounds } from '../../../store/App/selectors';
import { LatLngBounds } from 'leaflet';

const moment = require('moment');

const MAX_SENTINEL_ZOOM = 9;

enum SearchState {
    SelectAOI = 'SelectAOI',
    AOITooLarge = 'AOITooLarge',
    BeginSearch = 'BeginSearch',
    Loading = 'Loading',
    ErrorResult = 'ErrorResult',
    ViewResults = 'ViewResults',
    ViewImagery = 'ViewImagery',
}

interface MobileSatelliteRibbonProps {
    satelliteBottomSheetState: BottomSheetState;
    setSatelliteBottomSheetState: (state: BottomSheetState) => void;
}

const MobileSatelliteRibbon = (props: MobileSatelliteRibbonProps) => {
    const mapZoomLevel = useSelector(selectSentinelMapZoom);
    const mapBounds = useSelector(selectMapBounds);
    const satelliteAOI = useSelector(selectSentinelSelectedAOI);
    const sentinelFeatures = useSelector(selectSentinelFeatures);
    const sentinelFeaturesLoading = useSelector(selectSentinelFeaturesLoading);
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const sentinelError = useSelector(selectSentinelError);

    const dispatch = useDispatch();

    const [layer, setLayer] = useState<SentinelMetadata>(Constants.OVERLAY_DATA.SENTINEL.TRUE_COLOR);
    const [searchState, setSearchState] = useState<SearchState>(
        satelliteAOI ? SearchState.Loading : SearchState.SelectAOI
    );
    const [dateRange, setDateRange] = useState<DateRange>(last30Days);

    useEffect(() => {
        if (satelliteAOI) {
            dispatch(actionSentinelFetchFeatures(satelliteAOI, dateRange.startDate, dateRange.endDate, layer));
        }
    }, [satelliteAOI, dateRange, layer, dispatch]);

    useEffect(() => {
        const invalidateSentinelResults = () => {
            props.setSatelliteBottomSheetState(BottomSheetState.close);
            dispatch(actionSentinelResetSelectedFeature());
            dispatch(actionSentinelClearFeatures());
            dispatch(actionSentinelResetSelectedAOI());
        };

        if (selectedSentinelFeature) {
            if (UriHelper.tryGetParam('time')) {
                setDateRange(getDateFromShareLink(selectedSentinelFeature.date));
            }
            if (UriHelper.tryGetParam('layerKey')) {
                setLayer(getLayerFromShareLink(selectedSentinelFeature.layer));
            }
        }

        return () => {
            invalidateSentinelResults();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (sentinelFeaturesLoading) {
            setSearchState(SearchState.Loading);
        } else if (sentinelFeatures && selectedSentinelFeature) {
            setSearchState(SearchState.ViewImagery);
        } else if (sentinelFeatures && !selectedSentinelFeature) {
            setSearchState(SearchState.ViewResults);
        }
    }, [sentinelFeaturesLoading, selectedSentinelFeature, sentinelFeatures]);

    const handleClickQuickSearch = () => {
        if (mapZoomLevel <= MAX_SENTINEL_ZOOM) {
            setSearchState(SearchState.AOITooLarge);
        } else {
            props.setSatelliteBottomSheetState(BottomSheetState.mid);
            setSearchState(SearchState.BeginSearch);
            dispatch(actionBeginMobileSentinelSearch());
        }
    };

    const handleShowMore = () => {
        if (satelliteAOI) {
            const newStartDate = moment(dateRange.startDate).subtract(1, 'months').toDate();
            dispatch(actionSentinelFetchFeatures(satelliteAOI, newStartDate, dateRange.endDate, layer));
            setDateRange({ ...dateRange, startDate: newStartDate });
        }
    };

    const isValidSearchArea = mapZoomLevel > MAX_SENTINEL_ZOOM;
    const sentinelImageResolution =
        isValidSearchArea && mapBounds ? pixelResolutionForBounds(mapBounds as LatLngBounds) : '';

    switch (searchState) {
        case SearchState.SelectAOI:
            return (
                <Container>
                    <Button onClick={handleClickQuickSearch} disabled={!isValidSearchArea}>
                        {isValidSearchArea
                            ? `Search for ${sentinelImageResolution}/pixel imagery`
                            : 'Zoom in to search'}
                    </Button>
                </Container>
            );

        case SearchState.AOITooLarge:
            // Should not happen but incase let the user know why.
            return (
                <Container>
                    <Button onClick={handleClickQuickSearch}>
                        {isValidSearchArea
                            ? `Search for ${sentinelImageResolution}/pixel imagery`
                            : 'Zoom in to search'}
                    </Button>
                    <Error>This area is too large. Please zoom in closer to your Area of Interest</Error>
                </Container>
            );

        case SearchState.BeginSearch:
        case SearchState.Loading:
            return (
                <Container>
                    <MobileSatelliteLoading />
                </Container>
            );

        case SearchState.ErrorResult:
            return (
                <Container>
                    <Button onClick={handleClickQuickSearch}>
                        {isValidSearchArea
                            ? `Search for ${sentinelImageResolution}/pixel imagery`
                            : 'Zoom in to search'}
                    </Button>
                    <Error>{sentinelError ? sentinelError.message : 'An error occurred, Please try again'}</Error>
                </Container>
            );

        case SearchState.ViewResults:
            return (
                <Container>
                    <RibbonHeader>
                        <BackArrow
                            onClick={() => {
                                props.setSatelliteBottomSheetState(BottomSheetState.close);
                                dispatch(actionSentinelResetSelectedFeature());
                                dispatch(actionSentinelClearFeatures());
                                dispatch(actionSentinelResetSelectedAOI());
                                setSearchState(SearchState.SelectAOI);
                            }}
                        />
                        {sentinelFeatures && sentinelFeatures?.length !== 0 ? (
                            <SentinelFeatureHeader>{`Found ${
                                sentinelFeatures.length
                            } ${UserHelper.handleSatelliteLayerName(
                                sentinelFeatures[0].layer
                            )} images`}</SentinelFeatureHeader>
                        ) : null}
                    </RibbonHeader>
                    <SentinelFilter
                        onSelectLayer={(layer) => setLayer(layer)}
                        onSelectDateRange={(dateRange) => setDateRange(dateRange)}
                        layer={layer}
                        dateRange={dateRange}
                    />
                    {sentinelFeatures &&
                        sentinelFeatures.map((t, index) => {
                            return <SentinelItem key={index} feature={t} selectedFeature={selectedSentinelFeature} />;
                        })}
                    <Button
                        onClick={() => {
                            handleShowMore();
                        }}
                    >
                        Load More Satellite Imagery
                    </Button>
                    <SentinalFeaturesListTabBarPadding />
                </Container>
            );

        case SearchState.ViewImagery:
            return (
                <Container>
                    <RibbonHeader>
                        <RibbonDetails>
                            <BackArrow
                                onClick={() => {
                                    UriHelper.removeAllSentinelParametersFromURI();
                                    dispatch(actionSentinelResetSelectedFeature());
                                    dispatch(actionSentinelCloseDownload());
                                    dispatch(actionSentinelCloseShare());
                                    setSearchState(SearchState.ViewResults);
                                }}
                            />
                            {selectedSentinelFeature ? (
                                <SentinelFeatureHeader>{`${
                                    selectedSentinelFeature.resolution
                                }/pixel ${UserHelper.handleSatelliteLayerName(
                                    selectedSentinelFeature?.layer
                                )}`}</SentinelFeatureHeader>
                            ) : null}
                        </RibbonDetails>
                    </RibbonHeader>
                    {selectedSentinelFeature ? <MobileSentinelSearchResult feature={selectedSentinelFeature} /> : null}
                </Container>
            );
        default:
            return <React.Fragment />;
    }
};

export default MobileSatelliteRibbon;

const Container = styled.div`
    pointer-events: auto;
`;

const RibbonHeader = styled.div`
    display: flex;
    flex-direction: row;
`;

const RibbonDetails = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;

const SentinelFeatureHeader = styled.div`
    color: white;
    font-size: 20px;
    font-weight: 600;
    margin-right: 5px;
    padding: 8px 5px;
`;

const BackArrow = styled.div`
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M1%2011H21%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9.57153%201L0.999955%2011L9.57153%2021%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    align-self: center;
    margin: 0px 5px 0px 9px;
`;

const Button = styled(StyledButton)`
    margin: 5px auto;
    padding: 8px 10px;
    width: 250px;
`;

const Error = styled.p`
    color: red;
    font-weight: 600;
    text-align: center;
    margin: 12px 12px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.05);
`;

const SentinalFeaturesListTabBarPadding = styled.div`
    height: 64px; // Height of padding equal to the TabBar + list item margin
`;
