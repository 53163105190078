import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ApiUser from '../../../../api/api-user';

import UserHelper from '../../../../lib/user-helper';
import Analytics from '../../../../lib/user-analytics';

import { MobileButtonPrimary } from '../../Shared/button';
import { Content, ContentError, ContentErrorIcon } from '../../Shared/containers';

import { selectLoggedIn, selectMyProfile } from '../../../../store/Account/selectors';
import { Form, FormGroup, Input, Label } from '../../Shared/form';
import { MarkdownTextArea } from '../../../Shared/markdown-textarea';
import { PulseLoader } from '../../../Shared/pulse-loader';
import MobileEditAvatar from './mobile-edit-avatar';
import FileUtil from '../../../../lib/file-util';
import { toast } from 'react-toastify';

interface MobileEditDetailsProps {
    onSuccess: () => void;
    onCancel: () => void;
    onChange: (hasChanges: boolean) => void;
    onAvatarUpdate: (preview: string) => void;
}

const MAX_BIO_CHARACTERS = 2500;

const MobileEditDetails = (props: MobileEditDetailsProps) => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const myProfile = useSelector(selectMyProfile);

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [tagLine, setTagLine] = useState<string | undefined>(undefined);
    const [bio, setBio] = useState<string | undefined>(undefined);
    const [twitterUrl, setTwitterUrl] = useState<string | undefined>(undefined);
    const [facebookUrl, setFacebookUrl] = useState<string | undefined>(undefined);
    const [instagramUrl, setInstagramUrl] = useState<string | undefined>(undefined);
    const [linkedinUrl, setLinkedinUrl] = useState<string | undefined>(undefined);
    const [websiteUrl, setWebsiteUrl] = useState<string | undefined>(undefined);
    const [avatarFile, setAvatarFile] = useState<File | undefined>(undefined);
    const [avatarPreview, setAvatarPreview] = useState<string | undefined>(undefined);

    const [error, setError] = useState<string>('');
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('beforeunload', blockExit);

        return () => {
            setHasChanges(false);
            window.removeEventListener('beforeunload', blockExit);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => props.onChange(hasChanges), [hasChanges, props]);

    useEffect(() => {
        if (!isLoggedIn || !myProfile) {
            props.onCancel();
            return;
        }

        if (myProfile) {
            setFirstName(myProfile.firstName || '');
            setLastName(myProfile.lastName || '');
            setTagLine(myProfile.bio);
            setBio(myProfile.longDescription);
            setTwitterUrl(myProfile.twitterUrl);
            setFacebookUrl(myProfile.facebookUrl);
            setInstagramUrl(myProfile.instagramUrl);
            setLinkedinUrl(myProfile.linkedinUrl);
            setWebsiteUrl(myProfile.websiteUrl);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, myProfile]);

    useEffect(() => {
        if (avatarFile) {
            FileUtil.fileToBase64Image(avatarFile as File).then((base64Image) => {
                setAvatarPreview(base64Image);

                if (props.onAvatarUpdate) {
                    props.onAvatarUpdate(base64Image);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarFile]);

    useEffect(() => {
        if (
            avatarFile ||
            firstName !== myProfile?.firstName ||
            lastName !== myProfile?.lastName ||
            tagLine !== myProfile?.bio ||
            bio !== myProfile?.longDescription ||
            twitterUrl !== myProfile?.twitterUrl ||
            facebookUrl !== myProfile?.facebookUrl ||
            instagramUrl !== myProfile?.instagramUrl ||
            linkedinUrl !== myProfile?.linkedinUrl ||
            websiteUrl !== myProfile?.websiteUrl
        ) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [
        avatarFile,
        bio,
        facebookUrl,
        firstName,
        instagramUrl,
        lastName,
        linkedinUrl,
        tagLine,
        twitterUrl,
        websiteUrl,
        myProfile?.bio,
        myProfile?.facebookUrl,
        myProfile?.firstName,
        myProfile?.instagramUrl,
        myProfile?.lastName,
        myProfile?.linkedinUrl,
        myProfile?.longDescription,
        myProfile?.twitterUrl,
        myProfile?.websiteUrl,
    ]);

    const blockExit = (e: BeforeUnloadEvent) => {
        if (!hasChanges) return;

        e.preventDefault();

        if (e && e.returnValue) {
            e.returnValue = 'It seems you have some unsaved changes, do you want to continue?';
        }
        return 'It seems you have some unsaved changes, do you want to continue?';
    };

    const validForm = () => {
        if (!firstName || !lastName) {
            setError('First name and last name are required');
            return false;
        }

        if (bio && bio.length > MAX_BIO_CHARACTERS) {
            setError(`Bio must be less than ${MAX_BIO_CHARACTERS} characters`);
            return false;
        }

        return true;
    };

    const handleSubmitEdit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');

        submit(() => {
            props.onSuccess();
        });
    };

    const handleAvatarChange = (file?: File) => {
        if (file) {
            setAvatarFile(file);
        }
    };

    const handleAvatarUpdate = () => {
        setError('');

        submit(() => {
            toast.dark('Profile avatar updated successfully');
        });
    };

    const submit = (onSuccess: () => void) => {
        if (myProfile && hasChanges && validForm()) {
            setIsSubmitting(true);

            const name = myProfile.name;
            const avatarUrl = myProfile.avatarUrl && myProfile.avatarUrl.replace('/preview', '');

            const sanitizedTwitterUrl = twitterUrl ? UserHelper.setTwitterUrl(twitterUrl) : undefined;
            const sanitizedInstagramUrl = instagramUrl ? UserHelper.setInstagramUrl(instagramUrl) : undefined;
            const sanitizedFacebookUrl = facebookUrl ? UserHelper.setFacebookUrl(facebookUrl) : undefined;
            const sanitizedLinkedinUrl = linkedinUrl ? UserHelper.setLinkedInUrl(linkedinUrl) : undefined;

            ApiUser.updateMyProfile(
                name,
                tagLine,
                sanitizedTwitterUrl,
                sanitizedFacebookUrl,
                sanitizedInstagramUrl,
                websiteUrl,
                sanitizedLinkedinUrl,
                firstName,
                lastName,
                bio,
                avatarFile,
                avatarUrl
            )
                .then(() => {
                    Analytics.Event('My Dashboard', 'Updated profile', myProfile.userId);
                    setHasChanges(false);

                    onSuccess();
                })
                .catch((e) => {
                    setError(e.message);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    };

    if (!isLoggedIn || !myProfile) return null;

    return (
        <ProfileEditContent>
            <MobileEditAvatar
                profile={myProfile}
                onChange={(file) => handleAvatarChange(file)}
                onUpdate={() => handleAvatarUpdate()}
                avatarPreview={avatarPreview}
            />
            <ProfileEditDetails>
                <Form onSubmit={(e) => handleSubmitEdit(e)}>
                    <ProfileEditDetailHeader>Profile</ProfileEditDetailHeader>
                    <FormGroup>
                        <Label>First name</Label>
                        <Input
                            type="text"
                            placeholder="First name"
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Last name</Label>
                        <Input
                            type="text"
                            placeholder="First name"
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Tagline</Label>
                        <Input
                            type="text"
                            placeholder="Tagline"
                            value={tagLine}
                            onChange={(e) => {
                                setTagLine(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Bio</Label>
                        <MarkdownTextArea value={bio ?? ''} setInputValue={setBio} />
                    </FormGroup>

                    <ProfileEditDetailHeader>Social Media</ProfileEditDetailHeader>
                    <FormGroup className="inline">
                        <Label>
                            <ProfileEditSocialIcon src="/assets/social-share-icons/twitter.png" alt="X" />
                        </Label>
                        <Input
                            type="url"
                            placeholder="Enter X URL"
                            value={twitterUrl}
                            onChange={(e) => {
                                setTwitterUrl(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup className="inline">
                        <Label>
                            <ProfileEditSocialIcon src="/assets/social-share-icons/instagram.png" alt="Instagram" />
                        </Label>
                        <Input
                            type="url"
                            placeholder="Enter Instagram URL"
                            value={instagramUrl}
                            onChange={(e) => {
                                setInstagramUrl(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup className="inline">
                        <Label>
                            <ProfileEditSocialIcon src="/assets/social-share-icons/facebook.png" alt="Facebook" />
                        </Label>
                        <Input
                            type="url"
                            placeholder="Enter Facebook URL"
                            value={facebookUrl}
                            onChange={(e) => {
                                setFacebookUrl(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup className="inline">
                        <Label>
                            <ProfileEditSocialIcon src="/assets/social-share-icons/linkedin.png" alt="LinkedIn" />
                        </Label>
                        <Input
                            type="url"
                            placeholder="Enter LinkedIn URL"
                            value={linkedinUrl}
                            onChange={(e) => {
                                setLinkedinUrl(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup className="inline">
                        <Label>
                            <ProfileEditSocialIcon src="/assets/social-share-icons/website.png" alt="Website" />
                        </Label>
                        <Input
                            type="url"
                            placeholder="Enter Website URL"
                            value={websiteUrl}
                            onChange={(e) => {
                                setWebsiteUrl(e.target.value);
                            }}
                        />
                    </FormGroup>
                    <ProfileEditDetailHeader>&nbsp;</ProfileEditDetailHeader>
                    {error && (
                        <ContentError>
                            <ContentErrorIcon />
                            {error}
                        </ContentError>
                    )}
                    {!isSubmitting ? (
                        <ProfileEditSubmitButton type="submit" error={error ? true : false}>
                            APPLY CHANGES
                        </ProfileEditSubmitButton>
                    ) : (
                        <PulseLoader />
                    )}
                </Form>
            </ProfileEditDetails>
        </ProfileEditContent>
    );
};

export default MobileEditDetails;

const ProfileEditContent = styled(Content)`
    padding: 32px 16px 64px;
`;

const ProfileEditDetails = styled.div`
    padding-bottom: 64px;
`;

const ProfileEditDetailHeader = styled.h3`
    color: ${(props) => props.theme.color.white};

    margin-top: 32px;
`;

const ProfileEditSocialIcon = styled.img`
    margin-right: 8px;
`;

interface ErrorProps {
    error: boolean;
}

const ProfileEditSubmitButton = styled(MobileButtonPrimary)<ErrorProps>`
    margin: 0 auto;
    margin-top: ${(props) => (props.error ? '0px' : '20px')};
    background-color: gray;
    width: 100%;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
        -webkit-text-fill-color: black;
        color: black;
    }
`;
