import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { OrderDTO } from '../../../../api/model';
import { ListingTabEndOfList, ListingTabPanelEmpty, ListingTabPanelLoading } from '../Shared/panels';
import OrderStatus from '../../../Dashboard/SatelliteOrders/order-status';
import PriceWithCurrency from '../../../Shared/price-with-currency';
import SatelliteOrdersControl from './satellite-orders-control';

interface MobileProfileSatelliteOrdersProps {
    satelliteOrdersLoading: boolean;
    satelliteOrders: OrderDTO[] | undefined;
}

const typeStringForProvider = (type: string): string => {
    if (type === 'CSW' || type === 'CSW_CUSTOM') return 'New Collection';
    if (type === 'CSW_ARCHIVE' || type === 'CSW_CUSTOM_ARCHIVE') return 'Archival Imagery';
    if (type === 'CSW_CUSTOM_URGENT') return 'Urgent Collection';
    if (type === 'CSW_CUSTOM_STEREO') return 'Stereo Collection';
    if (type === 'CSW_CUSTOM_MONITORING') return 'Monitoring Collection';
    return 'Collection';
};

const MobileProfileSatelliteOrders = (props: MobileProfileSatelliteOrdersProps) => {
    if (props.satelliteOrdersLoading) {
        return <ListingTabPanelLoading />;
    }

    if (props.satelliteOrders) {
        if (props.satelliteOrders.length === 0) {
            return (
                <ListingTabPanelEmpty
                    iconUrl={`/assets/dashboard/satellite_yellow.svg`}
                    message="It looks like your Satellite Orders are empty"
                />
            );
        }

        return (
            <React.Fragment>
                {props.satelliteOrders.map((satelliteOrder, index) => (
                    <SatelliteOrderItem key={index}>
                        <SatelliteOrderItemDetails>
                            <SatelliteOrderNum>{satelliteOrder.orderNumber}</SatelliteOrderNum>
                            <SatelliteOrderStatus status={satelliteOrder.status} />
                            <SatelliteOrderDetails title="Type">
                                {typeStringForProvider(satelliteOrder.provider)}
                            </SatelliteOrderDetails>
                            <SatelliteOrderDetails title="Size">
                                {satelliteOrder.effectiveAreaInKm?.toFixed(2)} km²
                            </SatelliteOrderDetails>
                        </SatelliteOrderItemDetails>
                        <SatelliteOrderItemSubDetails>
                            <SatelliteOrderPrice>
                                <PriceWithCurrency
                                    value={satelliteOrder.payment.amount / 100}
                                    currency={satelliteOrder.payment.currency}
                                />
                            </SatelliteOrderPrice>
                            <SatelliteOrderDate>
                                {moment(satelliteOrder.created).format('MMM Do YYYY')}
                            </SatelliteOrderDate>
                            <SatelliteOrdersControl satelliteOrder={satelliteOrder} />
                        </SatelliteOrderItemSubDetails>
                    </SatelliteOrderItem>
                ))}
                <ListingTabEndOfList />
            </React.Fragment>
        );
    }

    return <React.Fragment />;
};

export default MobileProfileSatelliteOrders;

const SatelliteOrderItem = styled.div`
    display: flex;
    flex-direction: row;

    position: relative;
    margin: 5px 10px;
    padding: 10px;
    height: 110px;

    border: 1px solid ${(props) => props.theme.color.lightGray};
    border-radius: 4px;

    color: ${(props) => props.theme.color.white};

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;

        width: 75px;
        height: 75px;
        background: url('/assets/dashboard/satellite_white.svg') no-repeat bottom right;
        background-size: auto 100%;
        opacity: 0.15;
    }

    * {
        color: ${(props) => props.theme.color.white};
    }
`;

const SatelliteOrderItemSubDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    width: 30%;

    position: relative;

    .satorders-control {
        position: absolute;
        bottom: 0;
        right: 0;
    }
`;
const SatelliteOrderPrice = styled.strong`
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.fontSize.xlarge};
`;
const SatelliteOrderDate = styled.span`
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.fontSize.xlarge};
`;

const SatelliteOrderItemDetails = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;

    position: relative;
    width: 68%;
`;
const SatelliteOrderNum = styled.strong`
    width: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const SatelliteOrderStatus = styled(OrderStatus)`
    margin-top: 0 !important;
    margin-left: 0 !important;
`;

const SatelliteOrderDetails = styled.span<{ title: string }>`
    padding-right: 8px;

    &:before {
        content: '${(props) => props.title}: ';
        font-weight: 700;
        margin-right: 5px;
    }
`;
