import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import SoarLogo from '../../Shared/soar-logo';
import { Form, FormGroup, Input, Label } from '../../Shared/form';
import { Content, ContentFooter, ScrollableContainer } from '../../Shared/containers';
import { MobileButtonPrimary, MobileButtonText } from '../../Shared/button';
import OAuthSocialLogins from '../../../Registration/OAuth2/oauth-social-logins';
import { passwordActiveUrl, passwordInActiveUrl } from '../../../Registration/registration-icons';
import { PulseLoader } from '../../../Shared/pulse-loader';
import UserHelper from '../../../../lib/user-helper';
import CognitoUtil, { CognitoAuthResultType } from '../../../../lib/cognito-util';
import { actionLoginSuccess } from '../../../../store/Account/actions';
import Analytics from '../../../../lib/user-analytics';

interface MobileLoginProps {
    onRegister: () => void;
    onForgotPassword: () => void;
}

const MobileLogin = (props: MobileLoginProps) => {
    const dispatch = useDispatch();
    const apiLoginSuccess = (authResponse: CognitoAuthResultType) => dispatch(actionLoginSuccess(authResponse));

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loginSubmitting, setLoginSubmitting] = useState<boolean>(false);

    const handleSubmitLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setError(undefined);
        if (!UserHelper.isEmailValid(email)) {
            setError('Please check your email and try again');
            return;
        }

        if (!password) {
            setError('Please check your password and try again');
            return;
        }

        try {
            setLoginSubmitting(true);

            CognitoUtil.login(email, password)
                .then((authResponse) => {
                    apiLoginSuccess(authResponse);
                    Analytics.Event('Registration', 'Logged in');
                })
                .catch((err) => {
                    if (err.name === 'NotAuthorizedException') {
                        setError('Incorrect email or password');
                    } else {
                        setError(err.message);
                    }
                })
                .finally(() => {
                    setLoginSubmitting(false);
                });
        } catch (err) {
            setError(err.message.replace('401:', ''));
        }
    };

    return (
        <ScrollableContainer>
            <Content>
                <LoginSoarLogo width="60%" />
                <OAuthSocialLogins />
                <Form onSubmit={(e) => handleSubmitLogin(e)}>
                    <FormGroup>
                        <Label>Email</Label>
                        <LoginInput
                            type="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setError(undefined);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Password</Label>
                        <LoginInputPassword
                            data-sentry-block
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setError(undefined);
                            }}
                        />
                        <LoginFormPasswordIcon
                            showPassword={showPassword}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </FormGroup>
                    {error && (
                        <LoginModalError>
                            <LoginModalErrorIcon />
                            {error}
                        </LoginModalError>
                    )}
                    {!loginSubmitting ? (
                        <LoginSubmitButton type="submit" error={error ? true : false}>
                            SIGN IN
                        </LoginSubmitButton>
                    ) : (
                        <PulseLoader />
                    )}
                    <LoginFormForgotPassword onClick={() => props.onForgotPassword()}>
                        Forgot password?
                    </LoginFormForgotPassword>
                </Form>
            </Content>
            <MobileLoginFooter>
                New to Soar?
                <br />
                <MobileRegisterButton onClick={() => props.onRegister()}>Create your free account</MobileRegisterButton>
            </MobileLoginFooter>
        </ScrollableContainer>
    );
};

export default MobileLogin;

const MobileLoginFooter = styled(ContentFooter)`
    text-align: center;
    margin-bottom: 64px;
`;

const MobileRegisterButton = styled(MobileButtonPrimary)`
    margin: 8px 0;
    width: 100%;
`;

const LoginSoarLogo = styled(SoarLogo)`
    margin: 0 auto 32px;
`;

const LoginInput = styled(Input)``;

const LoginInputPassword = styled(LoginInput)`
    padding-right: 35px;
`;

interface PasswordIconProps {
    showPassword: boolean;
}

const LoginFormPasswordIcon = styled.div<PasswordIconProps>`
    background-image: url(${(props) => (props.showPassword ? passwordActiveUrl : passwordInActiveUrl)});
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;
    float: right;
    margin-top: -30px;
    margin-right: 5px;
    opacity: 0.75;
`;

const LoginModalError = styled.div`
    color: red !important;
    -webkit-text-fill-color: red;
    padding: 10px;
    padding-top: 0px;
    display: flex;
`;

const LoginModalErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%23FF0000'/%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

interface ErrorProps {
    error: boolean;
}

const LoginSubmitButton = styled(MobileButtonPrimary)<ErrorProps>`
    margin: 0 auto;
    margin-top: ${(props) => (props.error ? '0px' : '20px')};
    background-color: gray;
    width: 100%;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
        -webkit-text-fill-color: black;
        color: black;
    }
`;

const LoginFormForgotPassword = styled(MobileButtonText)`
    width: 100%;

    margin: 16px 0px;
    font-size: ${(props) => props.theme.fontSize.normal};
    color: ${(props) => props.theme.color.opaqueWhite} !important;
    text-decoration: underline;
`;
