import { useLocation } from 'react-router';
import { TabBarState } from './TabBar/tab-bar';
import { useEffect, useState } from 'react';

export const useMobileBrowserState = () => {
    const [tabBarState, setTabBarState] = useState<TabBarState | undefined>(undefined);
    const url = useLocation();

    useEffect(() => {
        switch (tabBarState) {
            case 'maps':
                window.history.pushState(null, '', '/maps');
                break;
            case 'satellites':
                window.history.pushState(null, '', '/satellites');
                break;
            case 'drawProject':
                window.history.pushState(null, '', '/draw');
                break;
            case 'discover':
                window.history.pushState(null, '', '/discover');
                break;
            case 'profile':
                window.history.pushState(null, '', '/profile');
                break;
            default:
                break;
        }
    }, [tabBarState]);

    useEffect(() => {
        if (url.pathname === '/' || url.pathname === '/maps' || url.pathname.includes('/maps')) {
            setTabBarState('maps');
        } else if (url.pathname === '/satellites') {
            setTabBarState('satellites');
        } else if (url.pathname === '/discover') {
            setTabBarState('discover');
        } else if (url.pathname.includes('/profile') && url.pathname !== '/profile') {
            setTabBarState('profile_view');
        } else if (url.pathname === '/profile') {
            setTabBarState('profile');
        } else if (url.pathname === '/password_reset') {
            setTabBarState('profile_reset');
        } else {
            setTabBarState('maps');
            console.log('No match for path: ', url.pathname);
        }
    }, [url]);

    return { tabBarState: tabBarState, setTabBarState: setTabBarState };
};
