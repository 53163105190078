import styled, { keyframes } from 'styled-components';

interface MapsRibbonListingCountProps {
    listingLocationCount?: number | undefined;
    onClick: () => void;
}

const MapsRibbonListingCount = (props: MapsRibbonListingCountProps) => {
    if (props.listingLocationCount === undefined) {
        return <SkeletonLoading />;
    }

    return (
        <Container>
            <ListingCountContainer onClick={props.onClick}>
                {`${
                    props.listingLocationCount
                        ? `${props.listingLocationCount.toLocaleString()} ${
                              props.listingLocationCount === 1 ? 'map' : 'maps'
                          } in this area`
                        : 'No maps in this area'
                }`}
            </ListingCountContainer>
        </Container>
    );
};

export default MapsRibbonListingCount;

const Container = styled.div`
    //background: red;
    background: rgb(34, 34, 34);
    position: absolute;
    width: 100%;
    height: 49px;
    margin-top: -15px;
    z-index: 1;
    opacity: 1;
    pointer-events: auto;
`;

const ListingCountContainer = styled.div`
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    font-size: 16px;
    color: white;
    margin: 0 0 0 15px;
    padding: 5px;
    text-align: center;
    pointer-events: auto;
    width: calc(100% - 30px);
`;

const shimmer = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
`;

const SkeletonLoading = styled.div`
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.05) 25%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0.05) 75%
    );
    background-size: 800px 100%;
    animation: ${shimmer} 1.5s infinite linear;
    border-radius: 6px;
    padding: 5px;
    margin: 0 15px 0 15px;
    width: auto;
    height: 34px;
`;
