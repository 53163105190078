import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ListingDTO } from '../../../../api/model';
import ListingHelper from '../../../../lib/listing-helper';
import Analytics from '../../../../lib/user-analytics';
import { actionClearSelectedListing } from '../../../../store/Map/MapSelection/actions';
import { actionActiveMapFetchById } from '../../../../store/Map/SuperMap/actions';
import ImagePreviewFailed from '../../../Shared/image-preview-failed';

interface SearchResultMasonaryCardProps {
    listing: ListingDTO;
}

const SearchResultMasonaryCard = (props: SearchResultMasonaryCardProps) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageFailed, setImageFailed] = useState(false);
    const dispatch = useDispatch();
    const clearSelectedListing = (clearListing: boolean) => dispatch(actionClearSelectedListing(clearListing));

    const handleSelectedListing = (mapId: number) => {
        clearSelectedListing(true);
        Analytics.Event('Mobile - Search Bar', 'Selected Listing', mapId);
        dispatch(actionActiveMapFetchById(mapId));
    };

    // NOTE: commented code is the sharing, removed for now and see if wanted..
    return (
        <MobileSearchCardContainer
            className={imageLoaded || imageFailed ? 'fade-in' : ''}
            data-testid="mobile-search-result-item"
            onClick={() => handleSelectedListing(props.listing.id)}
        >
            {imageFailed ? (
                <MobileSearchCardImageFailed />
            ) : (
                <MobileSearchCardImage
                    src={ListingHelper.getPreviewUrlForListing(props.listing.id, 'small')}
                    onLoad={() => setImageLoaded(true)}
                    onError={() => setImageFailed(true)}
                    loading="lazy"
                />
            )}
            <MobileSearchCardTitle>{props.listing.title}</MobileSearchCardTitle>
        </MobileSearchCardContainer>
    );
};

export default SearchResultMasonaryCard;

const MobileSearchCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: hsla(0, 100%, 100%, 0.01);
    opacity: 0;
    height: 120px;
    overflow: hidden;
    transition: opacity 0.5s, height 0.5s;

    &.fade-in {
        box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
            0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
        margin: 6px 3px;
        border: 1px solid rgb(255 255 255 / 10%);
        opacity: 1;
        height: auto;
    }
`;

const MobileSearchCardImageFailed = styled(ImagePreviewFailed)``;

const MobileSearchCardImage = styled.img`
    height: auto;
    max-width: 100%;
    object-fit: contain;
    border-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: hsla(0, 100%, 100%, 0.02);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
`;

const MobileSearchCardTitle = styled.div`
    margin: 4px 6px 2px 6px;
    color: white;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
`;
