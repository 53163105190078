import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { StyledButton } from '../../Shared/styled-button';

interface TileLayerErrorNoticeProps {
    setTileError: (tileError: boolean) => void;
    tileError: boolean;
    listingTitle: string;
}

const MIN_TILE_ERRORS_BEFORE_SHOWING_MODAL = 10; // Show the error modal after this many errors

const TileLayerErrorNotice = ({ setTileError, tileError, listingTitle }: TileLayerErrorNoticeProps) => {
    const [errorCount, setErrorCount] = useState(0);
    const [showTileError, setShowTileError] = useState(false);
    const [errorClosedByUser, setErrorClosedByUser] = useState(false);

    useEffect(() => {
        if (tileError && !showTileError) {
            setErrorCount(errorCount + 1);
        }
    }, [errorCount, tileError, showTileError]);

    useEffect(() => {
        if (errorCount >= MIN_TILE_ERRORS_BEFORE_SHOWING_MODAL) {
            setShowTileError(true);
        }
    }, [errorCount]);

    useEffect(() => {
        return () => {
            setShowTileError(false);
            setErrorCount(0);
            setErrorClosedByUser(false);
            setTileError(false);
        };
    }, [setTileError]);

    const handleCloseTileErrorNotice = () => {
        setShowTileError(false);
        setErrorCount(0);
        setErrorClosedByUser(true);
    };

    if (!showTileError || errorClosedByUser) {
        return null;
    }

    return (
        <TileLayerErrorNoticeContainer>
            <TileLayerBorderDiv>
                <TilelayerErrorNoticeRow>
                    <ErrorEmoji>😢</ErrorEmoji>
                    <TileErrorNoticeInnerContainer>
                        <ErrorText>Sorry! Unfortunately it looks like</ErrorText>
                        <ErrorText className="strong">{listingTitle}</ErrorText>
                        <ErrorText>
                            map feed is intermittent at this time and may not be displaying correctly. This is a source
                            provider issue.
                            <br />
                            <br />
                            Please check back later if it is not working.
                            <br />
                            <br />
                            Thank you.
                        </ErrorText>
                    </TileErrorNoticeInnerContainer>
                </TilelayerErrorNoticeRow>
                <ErrorButton onClick={handleCloseTileErrorNotice}>OK</ErrorButton>
            </TileLayerBorderDiv>
        </TileLayerErrorNoticeContainer>
    );
};

export default TileLayerErrorNotice;

const TileLayerErrorNoticeContainer = styled.div`
    position: fixed;
    z-index: 9999;
    top: 280px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    background-color: ${(props) => props.theme.color.white};
    border-radius: ${(props) => props.theme.borderRadius};
    max-width: 90%;
    width: 415px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 11px 14px -7px, rgba(0, 0, 0, 0.24) 0px 23px 36px 3px,
        rgba(0, 0, 0, 0.22) 0px 9px 44px 8px;
    border: 1px solid rgb(81, 81, 81);

    @media only screen and (max-width: 600px) {
        padding: 10px;
    }
`;

const TileLayerBorderDiv = styled.div`
    border: 1px solid ${(props) => props.theme.color.lightGray};
    border-radius: ${(props) => props.theme.borderRadius};
    padding: 5px;
    padding-bottom: 10px;
`;

const TilelayerErrorNoticeRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const TileErrorNoticeInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

const ErrorEmoji = styled.span`
    font-size: 28px;
    margin: 0px 7px 0px 5px;
`;

const ErrorText = styled.p`
    font-size: 16px;
    margin-bottom: 20px;

    &.strong {
        font-weight: 700;
        overflow-wrap: break-word;
    }

    @media only screen and (max-width: 600px) {
        font-size: 14px;
        margin-bottom: 15px;
    }
`;

const ErrorButton = styled(StyledButton)`
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    width: 120px;
    margin: 0 auto;
    border: 1px solid ${(props) => props.theme.color.lightGray};
`;
