import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSentinelQuery } from '../../../lib/uri-helper';

import { actionSentinelSelectAOI, actionSentinelSelectFeature } from '../../../store/Map/Sentinel/actions';
import { actionFlyTo } from '../../../store/App/actions';

import SentinelAOISelect from '../../MapView/Sentinel/sentinel-aoi-select';
import SentinelFeatures from '../../MapView/Sentinel/sentinel-features';

const MobileSentinelLayers = () => {
    const dispatch = useDispatch();

    const sentinelShareFeature = useSentinelQuery();

    useEffect(() => {
        if (sentinelShareFeature) {
            dispatch(actionFlyTo(sentinelShareFeature.bbox, undefined, true));
            dispatch(actionSentinelSelectAOI(sentinelShareFeature.bbox));

            dispatch(actionSentinelSelectFeature(sentinelShareFeature));
        }
    }, [dispatch, sentinelShareFeature]);

    return (
        <React.Fragment>
            <SentinelAOISelect />
            <SentinelFeatures />
        </React.Fragment>
    );
};

export default MobileSentinelLayers;
